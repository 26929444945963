import getInstance from './config';

export default class UserApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/user', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/user/${id}`);
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/user/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/user/admin', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/user/${id}`);
    return data;
  }
}
