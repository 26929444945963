import React from 'react';
import { Translate } from 'react-redux-i18n';

const VehicleServiceType = {
  FUEL_SUPPLY: 1,
  AUTOMOTIVE_SERVICE: 2,
  ALL: 3,
};

export const VehicleServiceTypeArray = [
  { id: VehicleServiceType.FUEL_SUPPLY, name: <Translate value="enum.vehicleServiceType.fuelSupply" /> },
  { id: VehicleServiceType.AUTOMOTIVE_SERVICE, name: <Translate value="enum.vehicleServiceType.automotiveServices" /> },
  { id: VehicleServiceType.ALL, name: <Translate value="enum.vehicleServiceType.all" /> },
];

export const getVehicleServiceTypeTitle = (value) => (VehicleServiceTypeArray.find((o) => o.id === value).name);

export default VehicleServiceType;
