import getInstance from './config';

export default class AuthApi {
  static async auth(parameters) {
    const instance = await getInstance(true);
    const { data } = await instance.post('/auth', parameters);
    return data;
  }

  static async sendRecoveryEmail(params) {
    const instance = await getInstance(true);
    const { data } = await instance.post('/user/password-recovery', params);
    return data;
  }

  static async passwordRecovery(params) {
    const instance = await getInstance(true);
    const { data } = await instance.put('/user/password-recovery', params);
    return data;
  }

  static async me() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/user/me');
    return data;
  }
}
