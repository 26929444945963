import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import { addLoading, removeLoading } from './loading';
import AvailableRegion from '../../api/available-region';

export const ACTTION_CREATE_AVAILABLE_REGION = 'ACTTION_CREATE_AVAILABLE_REGION';

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const response = await AvailableRegion.create(data);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
