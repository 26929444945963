import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import FuelSupplyRequests from '../../api/fuel-supply';

export const ACTION_SAVE_FUEL_SUPPLY_REPORT = 'ACTION_SAVE_FUEL_SUPPLLY_REPORT';

export const ACTION_CLEAN_FUEL_SUPPLY = 'ACTION_CLEAN_FUEL_SUPPLLY';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_FUEL_SUPPLY,
  payload: null,
});

export const cleanReport = () => ({
  type: ACTION_SAVE_FUEL_SUPPLY_REPORT,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getReport = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanReport());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
    };

    dispatch({
      type: ACTION_SAVE_FUEL_SUPPLY_REPORT,
      payload: null,
    });

    const response = await FuelSupplyRequests.getReport(parameters);

    dispatch({
      type: ACTION_SAVE_FUEL_SUPPLY_REPORT,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
