import getInstance from './config';

export default class OrderApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/dashboard', { params });
    return data;
  }

  static async getTipsPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/dashboard/tips', { params });
    return data;
  }

  static async getCount(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/count', { params });
    return data;
  }

  static async getAbsoluteValues() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/count-general-values');
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/order/${id}`);
    return data;
  }

  static async getExportCsv(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/export-csv', { params });
    return data;
  }

  static async getExportCsvWithDriverRating(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/order/export-csv-with-driver-rating', { params });
    return data;
  }

  static async getStatesSelectives() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/available-region/dashboard/states-registered');
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/order/${id}/assign-driver`, userData);
    return data;
  }

  static async updateStatus(params) {
    const instance = await getInstance();
    const { data } = await instance.put('/v1/order/dashboard/views', params);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/order', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/order/${id}`);
    return data;
  }

  static async cancel(body) {
    const { id, reason, description } = body;
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/order/${id}/cancel`, { reason, description });
    return data;
  }
}
