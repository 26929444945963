import React from 'react';
import moment from 'moment-timezone';
import {
  Divider,
  Layout,
  Row,
  Col,
  Breadcrumb, 
  Icon, 
  Collapse,
  message
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { navigate } from '@reach/router';
import { SearchOutlined } from '@ant-design/icons';

import { OrderActions, AuthActions, DriverActions } from '../../../../app/redux/actions';
import {
  OrderSelectors,
  AuthSelectors,
  LoadingSelectors,
  DriverSelectors
} from '../../../../app/redux/reducers';

import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import OrderStatus from '../../../../app/enum/orderStatusType';
import QueryStringUtils from '../../../../app/utils/queryString';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDatePicker from '../../../../components/shared/AdvancedDatePicker';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  orderNumber: null,
  fuelId: null,
  orderStatus: OrderStatus.PROVIDED,
};

class DriverRatingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();
    const { getDriversSelectives } = this.props;

    if (params) {
      this.setState({ params });
    }

    getDriversSelectives();
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    params = { ...params, ...this.state.params };
    getPaginated(params);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  exportCsv() {
    const { exportCsv } = this.props;
    const { params } = this.state;

    try {
      exportCsv(params, () => {
        message.success(I18n.t('routes.panel.administration.items.driverRating.messages.exportCsvSuccess'));
      });
    } catch (e) {
      message.error(I18n.t('routes.panel.administration.items.driverRating.errors.exportCsv'));
    }
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      tipsPaginated,
      loading,
      driverSelectives,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.driverRating.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.administration.items.driverRating.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.driverRating.advancedFilter.orderNumber.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.driverRating.advancedFilter.orderNumber.key'), val)}
                    value={params && params.orderNumber}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.driverRating.advancedFilter.driverId.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.driverRating.advancedFilter.driverId.key'), val)}
                    options={driverSelectives}
                    value={params && params.driverId}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.administration.items.driverRating.advancedFilter.createdAtStart.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.driverRating.advancedFilter.createdAtStart.key'), val)}
                    value={params && params.createdAtStart && moment(params.createdAtStart)}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.administration.items.driverRating.advancedFilter.createdAtEnd.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.driverRating.advancedFilter.createdAtEnd.key'), val)}
                    value={params && params.createdAtEnd && moment(params.createdAtEnd)}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="driverRatings">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.driverRating.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.driverRating.pageTitle')}
                </h2>
              </Col>

              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.driverRating.exportCsvButtonText')}
                  onClick={() => this.exportCsv()}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.driverRating.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={tipsPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.orderNumber.key'),
                      title: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.orderNumber.title'),
                      render: (value) => `#${value}` || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.clientEvaluation.key'),
                      title: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.clientEvaluation.title'),
                      render: (value) => value ? ' * '.repeat(value) : 'N/A',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.tipAmount.key'),
                      title: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.tipAmount.title'),
                      render: (value) => `R$ ${value},00`,
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.driver.key'),
                      title: I18n.t('routes.panel.administration.items.driverRating.dataTable.columns.driver.title'),
                      render: (value) => <AdvancedButton
                        shape="circle"
                        type="link"
                        text={value && value.name}
                        disabled={value && value.deletedAt}
                        onClick={() => navigate(`${I18n.t('routes.panel.administration.items.driver.url')}/${value.id}`)}
                      /> || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tipsPaginated: OrderSelectors.getTipsPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  driverSelectives: DriverSelectors.getSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(OrderActions.getTipsPaginated(parameters)),
  remove: (id, callback) => dispatch(OrderActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getDriversSelectives: () => dispatch(DriverActions.getSelectives()),
  exportCsv: (parameters, callback) => dispatch(OrderActions.exportCsvWithDriverRating(parameters, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverRatingList);
