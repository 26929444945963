import Url from '../config/api.config';

function getBaseUrl() {
  const { hostname } = window.location;
  let apiUrl;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
      apiUrl = Url.localhost;
      break;
    case 'dashboarddev.versafuel.com.br':
      apiUrl = Url.development;
      break;
    case 'dashboard.versafuel.com.br':
      apiUrl = Url.production;
      break;
    default:
      apiUrl = Url.localhost;
  }

  return apiUrl;
}

function getBaseUrlAuth() {
  const { hostname } = window.location;
  let apiUrl;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
      apiUrl = Url.localhostAuth;
      break;
    case 'dev.project.com':
      apiUrl = Url.developmentAuth;
      break;
    case 'project.com':
      apiUrl = Url.productionAuth;
      break;
    default:
      apiUrl = Url.localhostAuth;
  }

  return apiUrl;
}

function getAuthToken() {
  const { hostname } = window.location;
  let key;

  switch (hostname) {
    case 'localhost':
    case 'www.localhost':
    case '127.0.0.1':
      key = Url.authApiKeyDev;
      break;
    case 'dashboarddev.versafuel.com.br':
      key = Url.authApiKeyDev;
      break;
    case 'dashboard.versafuel.com.br':
      key = Url.authApiKey;
      break;
    default:
      key = Url.authApiKeyDev;
  }

  return key;
}

export function handleAxiosError(err) {
  if (err.response && err.response.status === 400 && err.response.data && err.response.data.error) {
    throw new Error(err.response.data.error);
  }
  if (err.response && err.response.status === 401) {
    // throw new Error(CodeErrors.INVALID_TOKEN);
  }
  const error = {
    url: err.config && err.config.url,
    method: err.config && err.config.method,
    headers: err.config && err.config.headers,
    data: err.config && err.config.data,
    response: null,
    status: null,
    message: err.message,
  };
  if (err.response) {
    error.response = err.response.data;
    error.status = err.response.status;
  }
  return error;
}

export function serializeOrderBy (orderBy) {
  if (!orderBy || typeof orderBy !== 'string') return 'createdAt';

  if (!orderBy.includes(',')) {
    return orderBy.replace('.', '_');
  }

  return orderBy.split(',').map((field) => field.replace('.', '_')).join(',');
}

export default {
  getBaseUrl,
  getBaseUrlAuth,
  getAuthToken,
};
