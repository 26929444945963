import React from 'react';
import moment from 'moment-timezone';
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import { TimeGridScheduler, classes } from '@remotelock/react-week-scheduler';
import '@remotelock/react-week-scheduler/index.css';
import Loading from '../Loading/Loading';

const defaultWeekScheduleDate = '2020-01-05';

class WeekScheduler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      schedule: [],
      loading: null,
    };
  }

  componentDidMount() {
    let schedule = [];
    if (this.props.schedule && this.props.schedule.length) {
      schedule = this.props.schedule.map((s) => [
        moment(defaultWeekScheduleDate).add(s.day, 'days').set({ hour: s.startAt.substring(0, 2), minute: s.startAt.substring(3, 5) }),
        moment(defaultWeekScheduleDate).add(s.day, 'days').set({ hour: s.endAt.substring(0, 2), minute: s.endAt.substring(3, 5) }),
      ]);
    }

    this.setState({ schedule });
  }

  setSchedule(schedule) {
    if (schedule === this.state.schedule) { return; }
    this.setState({ loading: true });
    schedule.forEach((timeInterval, index) => {
      if (moment(timeInterval[0]).isSame(moment(timeInterval[1]).subtract(20, 'minutes'))) {
        schedule.splice(index, 1);
      }
    });
    this.setState({ schedule });
    setTimeout(() => {
      this.setFormattedSchedule();
      this.setState({ loading: false });
    }, 500);
  }

  setFormattedSchedule() {
    const formattedSchedule = [];
    this.state.schedule.forEach((s) => {
      formattedSchedule.push({
        day: moment(s[0]).isoWeekday(),
        startAt: moment(s[0]).format('HH:mm'),
        endAt: moment(s[1]).format('HH:mm'),
      });
    });
    this.props.setSchedule(formattedSchedule);
    return formattedSchedule;
  }

  formatSchedule(weekScheduler) {
    return weekScheduler.map((range) => range.map((dateString) => new Date(dateString)));
  }

  render() {
    const { schedule, loading } = this.state;
    return (
      <>
        {loading && <Loading />}
        <TimeGridScheduler
          classes={classes}
          originDate={new Date('2020-01-07')}
          schedule={schedule}
          onChange={(dates) => this.setSchedule(dates)}
          style={{ height: 1000 }}
          verticalPrecision={10}
          visualGridVerticalPrecision={60}
        />
      </>
    );
  }
}

export default WeekScheduler;
