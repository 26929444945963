import React from 'react';
import { Breadcrumb, Col, Divider, Icon, Layout, message, Row, Spin } from 'antd';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { AvailableRegionActions } from '../../../app/redux/actions';
import { LoadingSelectors } from '../../../app/redux/reducers';
import AvailibleRegionForm from '../../../app/forms/AvailableRegionForm/AvailableRegionForm';

class AvailibleRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  save(id, data) {
    const { create } = this.props;
    try {
      create(data, () => {
        message.success(I18n.t('routes.panel.administration.items.availableRegion.details.messages.successCreate'));
      });
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { loading } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.administration.items.availableRegion.url')}>
                <Icon type={I18n.t('routes.panel.administration.items.availableRegion.icon')} />
                <span>{I18n.t('routes.panel.administration.items.availableRegion.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.availableRegion.pageTitleAdd')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin spinning={loading > 0} tip={I18n.t('shared.loading')}>
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.availableRegion.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.availableRegion.pageTitleAdd')}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t('routes.panel.administration.items.availableRegion.pageDescriptionAdd')}
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <AvailibleRegionForm submitFunction={(dataId, data) => this.save(dataId, data)} loading={loading} />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  create: (data, callback) => dispatch(AvailableRegionActions.create(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AvailibleRegion);
