import React from 'react';
import {
  Divider,
  Layout,
  Icon,
  Row,
  Col,
  Breadcrumb,
  Collapse,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined } from '@ant-design/icons';

import { AuthActions, EmailActions } from '../../../../app/redux/actions';
import {
  AuthSelectors,
  LoadingSelectors,
  EmailSelectors,
} from '../../../../app/redux/reducers';
import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import { GetEmailTypeTitle, EmailTypeArray } from '../../../../app/enum/emailType';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import QueryStringUtils from '../../../../app/utils/queryString';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';

const { Panel } = Collapse;

const defaultParams = {
  title: null,
  emailType: null,
};

const qs = new QueryStringUtils();

class EmailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();

    if (params) {
      this.setState({ params });
    }
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    getPaginated(params);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      paginatedData,
      loading,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.manage.items.email.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.manage.items.email.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.manage.items.email.advancedFilter.title.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.manage.items.email.advancedFilter.title.key'), val)}
                    placeholder={I18n.t('routes.panel.manage.items.email.advancedFilter.title.placeholder')}
                    value={params && params.title}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.manage.items.email.advancedFilter.emailType.label')}
                    options={EmailTypeArray}
                    onChange={(value) => this.fieldChange('emailType', value)}
                    value={params && params.emailType && parseInt(params.emailType, 10)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="orders">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.manage.items.email.icon')} />
                  </span>
                  {I18n.t('routes.panel.manage.items.email.pageTitle')}
                </h2>
              </Col>

              {I18n.t('routes.panel.manage.items.email.disableAddButton') !== 'y' && (
                <Col
                  className="text-right"
                  span={12}
                >
                  <AdvancedButton
                    text={I18n.t('routes.panel.manage.items.email.addNewButtonText')}
                    href={`${I18n.t('routes.panel.manage.items.email.url')}/add`}
                    icon={<Icon type={I18n.t('routes.panel.manage.items.email.icon')} />}
                  />
                </Col>
              )}
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.manage.items.email.dataTable.columns.title.key'),
                      title: I18n.t('routes.panel.manage.items.email.dataTable.columns.title.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.manage.items.email.dataTable.columns.emailType.key'),
                      title: I18n.t('routes.panel.manage.items.email.dataTable.columns.emailType.title'),
                      render: (value) => (value ? GetEmailTypeTitle(value) : '--'),
                    },
                    {
                      key: I18n.t('routes.panel.manage.items.email.dataTable.columns.scheduledDate.key'),
                      title: I18n.t('routes.panel.manage.items.email.dataTable.columns.scheduledDate.title'),
                      render: (value) => (value ? DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm') : I18n.t('routes.panel.manage.items.email.dataTable.columns.scheduledDate.sendNow')),
                    },
                    {
                      key: I18n.t('routes.panel.manage.items.email.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.manage.items.email.dataTable.columns.createdAt.title'),
                      render: (value) => DateUtils.humanizeDateTime(value, 'DD/MM/YYYY HH:mm'),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: EmailSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(EmailActions.getPaginated(parameters)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailList);
