/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, message, Divider, Modal,
} from 'antd';
import * as yup from 'yup';

import { I18n } from 'react-redux-i18n';
import { InboxOutlined, SaveOutlined } from '@ant-design/icons';

import Dragger from 'antd/lib/upload/Dragger';
import AdvancedInput from '../../../components/shared/AdvancedInput/AdvancedInput';
import { AdministratorSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton/AdvancedButton';
import { csvToJson } from '../../utils/csvToJson';

class AvailibleRegionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadedFile: null,
      form: {
        label: null,
        city: null,
        state: null,
        coordinates: null,
      },
      isModalVisible: null,
    };
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      label: yup.string().required(),
      city: yup.string().required(),
      state: yup.string().required(),
      coordinates: yup.array().required(),
    });

    schema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else {
          const { submitFunction } = this.props;
          submitFunction(null, form);
        }
      })
      .catch(() => I18n.t('shared.genericError'));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  customRequest(onSuccess, onError, file) {
    const reader = new FileReader();
    reader.readAsText(file, 'UTF-8');

    reader.onload = (evt) => {
      this.setState({
        form: {
          ...this.state.form,
          coordinates: csvToJson(evt.target.result),
        },
      });
      return onSuccess(message.success(I18n.t('routes.panel.administration.items.availableRegion.messages.addSuccess')), file);
    };
    reader.onerror = () => {
      message.error(I18n.t('routes.panel.users.userCsvDeleteModal.errors.generic'));
    };
  }

  render() {
    const { loading } = this.props;
    const { form } = this.state;
    return (
      <>
        <Row>
          {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.availableRegion.details.form.name.label')}
                    value={form && form.label}
                    onChange={(val) => this.fieldChange('label', val)}
                  />
                </Col>
                <Col span={6}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.availableRegion.details.form.state.label')}
                    value={form && form.state}
                    onChange={(val) => this.fieldChange('state', val)}
                  />
                </Col>
                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.availableRegion.details.form.city.label')}
                    value={form && form.city}
                    onChange={(val) => this.fieldChange('city', val)}
                  />
                </Col>
              </Row>
              <Divider />
              <AdvancedButton
                text={I18n.t('routes.panel.administration.items.availableRegion.buttonMessage')}
                onClick={() => this.setState({ ...this.state, isModalVisible: true })}
              />
              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.administration.items.user.url')}
                  />
                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />
                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
          )}
        </Row>
        <Modal
          title={I18n.t('routes.panel.administration.items.availableRegion.pageTitle')}
          visible={this.state.isModalVisible}
          onOk={() => this.setState({ isModalVisible: false })}
          onCancel={() => this.setState({ isModalVisible: false })}
        >
          <Col>
            <a
              href="/assets/files/csv-upload-regions.csv"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong className="templateCompany">{I18n.t('routes.panel.administration.items.availableRegion.instructionModalOne')}</strong> {I18n.t('routes.panel.administration.items.availableRegion.instructionModalTwo')}
            </a>

          </Col>
          <Dragger
            name="file"
            multiple={false}
            customRequest={({ onSuccess, onError, file }) => this.customRequest(onSuccess, onError, file)}
            accept=".csv"
          >
            <div className="ant-upload-drag-icon">
              <InboxOutlined />
            </div>
            <p className="ant-upload-text">{I18n.t('routes.panel.administration.items.availableRegion.modalMessage')} </p>
          </Dragger>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: AdministratorSelectors.getDetails(state),
});

export default connect(mapStateToProps, null)(AvailibleRegionForm);
