import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Divider,
  Switch,
  message,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors,
  LoadingSelectors,
  SettingsSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { SettingsActions, DriverScheduleActions } from '../../redux/actions';

class SettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        settings: [],
      },
    };
    this.dataTableRef = React.createRef();
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { selectives } = this.props;

    if (selectives) {
      Object.keys(selectives).forEach((key) => { selectives[key] = selectives[key].replace('.', ','); });
      this.setState({ form: selectives });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { form } = this.state;

    const { submitFunction } = this.props;

    Object.keys(form).forEach((key) => {
      form[key] = form[key].includes('R$') ? form[key].split('R$ ')[1] : form[key];
    });

    submitFunction(form);
  }

  getSelectives() {
    const { getSelectives } = this.props;
    getSelectives();
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  syncDriversWeekSchedules() {
    const { driverScheduleSyncWeek } = this.props;

    try {
      driverScheduleSyncWeek();
      message.success(I18n.t('routes.panel.manage.items.settings.messages.syncDriverWeekSchedule'));
    } catch(e) {
      message.error(I18n.t('routes.panel.manage.items.settings.messages.syncDriverWeekScheduleFailed'));
    }

  }

  render() {
    const { form } = this.state;

    return (
      <Row>
        <Col>
          <form
            name="form"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            <Row gutter={16}>
              <Col span={24}>
                <div className="d-flex mb-4">
                  <Switch
                    className="mr-2"
                    checked={form.deliveryFastEnabled === 'true'}
                    onChange={(val) => this.fieldChange('deliveryFastEnabled', val ? 'true' : 'false')}
                  />
                  <p>{I18n.t('routes.panel.manage.items.settings.form.deliveryFastEnabled.label')}</p>
                </div>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <AdvancedInput
                  isMoney
                  label={I18n.t('routes.panel.manage.items.settings.form.fullTankPrice.label')}
                  value={form && form.fullTankPrice}
                  onChange={(val) => this.fieldChange('fullTankPrice', val)}
                />
              </Col>
              <Col span={8}>
                <AdvancedInput
                  type="number"
                  label={I18n.t('routes.panel.manage.items.settings.form.fullTankLiters.label')}
                  value={form && form.fullTankLiters}
                  onChange={(val) => this.fieldChange('fullTankLiters', val)}
                />
              </Col>
              <Col span={8}>
                <AdvancedInput
                  isMoney
                  label={I18n.t('routes.panel.manage.items.settings.form.deliveryFee.label')}
                  value={form && form.deliveryFee}
                  onChange={(val) => this.fieldChange('deliveryFee', val)}
                />
              </Col>
              <Col span={12}>
                <AdvancedInput
                  type="number"
                  label={I18n.t('routes.panel.manage.items.settings.form.deliveryForecastMinutes.label')}
                  value={form && form.deliveryForecastMinutes}
                  onChange={(val) => this.fieldChange('deliveryForecastMinutes', val)}
                />
              </Col>
              <Col span={12}>
                <AdvancedInput
                  type="number"
                  label={I18n.t('routes.panel.manage.items.settings.form.serviceStationFeePercentage.label')}
                  value={form && form.serviceStationFeePercentage}
                  onChange={(val) => this.fieldChange('serviceStationFeePercentage', val)}
                />
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col>
                <AdvancedButton
                  onClick={() => this.syncDriversWeekSchedules()}
                  type="button"
                  text={I18n.t('routes.panel.manage.items.settings.form.syncDriverWeekScheduleButton.label')}
                />
              </Col>
            </Row>

            <Row>
              <Col
                span={24}
                className="text-right"
              >
                <AdvancedButton
                  type="link"
                  text={I18n.t('shared.backButtonText')}
                  href={I18n.t('routes.panel.administration.items.fuel.url')}
                />

                <Divider
                  className="form-button-divider"
                  type="vertical"
                />

                <AdvancedButton
                  htmlType="submit"
                  text={I18n.t('shared.saveButtonText')}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>

          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  selectives: SettingsSelectors.getSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSelectives: () => dispatch(SettingsActions.getSelectives()),
  driverScheduleSyncWeek: () => dispatch(DriverScheduleActions.syncWeek()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsForm);
