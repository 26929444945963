import {
  ACTION_SAVE_SETTINGS_SELECTIVES,
} from '../actions/settings';

const initialState = {
  selectives: null,
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_SETTINGS_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getSelectives(state) {
  return state.settings.selectives;
}
