import getInstance from './config';

export default class ModelApi {
  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/model/${id}`);
    return data;
  }

  static async getSelectives(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/model/selectives', { params });
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/model/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/model', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/model/${id}`);
    return data;
  }
}
