import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  DeleteOutlined, EditOutlined, SearchOutlined, UnorderedListOutlined,
} from '@ant-design/icons';
import { Link } from '@reach/router';
import DataTable from '../../../../components/shared/DataTable';
import { AuthActions, VehicleActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, VehicleSelectors } from '../../../../app/redux/reducers';
import { ProfileType } from '../../../../app/enum/profileType';
import { getVehicleServiceTypeTitle } from '../../../../app/enum/vehicleServiceType';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import { removeSpecialChars } from '../../../../app/utils/string';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import QueryStringUtils from '../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  plate: null,
  email: null,
  cellphone: null,
};

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();
    const { cleanDetails } = this.props;

    if (params) {
      this.setState({ params });
    }

    cleanDetails();
  }

  getPaginatedData(params) {
    const { me } = this.props;
    params = {
      ...params,
      profileTypeList: `${ProfileType.USER.toString()}`,
    };

    if (params && params.cellphone) {
      params = {
        ...params,
        cellphone: `55${removeSpecialChars(params.cellphone)}`,
      };
    }

    const { getPaginated } = this.props;

    params = {
      ...params,
      fromUser: true,
    };
    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.administration.items.vehicle.messages.deleteSuccess'));
      this.dataTableRef.current.reset();
      this.applyAdvancedFilters();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;

    const {
      vehiclesPaginated,
      loading,
      me,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.vehicle.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.administration.items.vehicle.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.vehicle.advancedFilter.plate.label')}
                    onChange={(val) => this.fieldChange('plate', val)}
                    value={params && params.plate}
                  />
                </Col>

              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.vehicle.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.vehicle.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.vehicle.addNewButtonText')}
                  href={`${I18n.t('routes.panel.administration.items.vehicle.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.vehicle.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={vehiclesPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.plate.key'),
                      title: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.plate.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.modelName.key'),
                      title: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.modelName.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.serviceType.key'),
                      title: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.serviceType.title'),
                      render: (value) => (value && getVehicleServiceTypeTitle(value)) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.actions.key'),
                      title: '',
                      render: (id, row) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip title={I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.actions.removeText')}>
                              <Button
                                type="link"
                                disabled={me && me.id === id}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip title={I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.actions.goToDetailsText')}>
                            <Link to={`${I18n.t('routes.panel.administration.items.vehicle.url')}/${id}`}>
                              <EditOutlined />
                            </Link>
                          </Tooltip>
                          <Tooltip title={I18n.t('routes.panel.administration.items.vehicle.dataTable.columns.actions.goToReportText')}>
                            <Link
                              className="ml-3"
                              to={`${I18n.t('routes.panel.administration.items.fuelSupply.url')}?vehiclePlate=${row.plate}`}
                            >
                              <UnorderedListOutlined />
                            </Link>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vehiclesPaginated: VehicleSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(VehicleActions.getPaginated(parameters, me)),
  cleanDetails: (parameters, me) => dispatch(VehicleActions.cleanDetails(parameters, me)),
  remove: (id, callback) => dispatch(VehicleActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleList);
