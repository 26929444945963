import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

import {
  addLoading,
  removeLoading,
} from './loading';
import BrandRequests from '../../api/brand';

export const ACTION_SAVE_BRAND_SELECTIVES = 'ACTION_SAVE_BRAND_SELECTIVES';
export const ACTION_CLEAN_BRAND = 'ACTION_CLEAN_BRAND';

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getSelectives = (params, callback) => async (dispatch) => {
  if (!params.withoutLoading) dispatch(addLoading());

  try {
    dispatch({
      type: ACTION_SAVE_BRAND_SELECTIVES,
      payload: null,
    });

    const { rows } = await BrandRequests.getSelectives(params);
    dispatch({
      type: ACTION_SAVE_BRAND_SELECTIVES,
      payload: rows,
    });

    if (callback && typeof callback === 'function') {
      callback(rows);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    if (!params.withoutLoading) dispatch(removeLoading());
  }
};
