/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */

export function csvToJson(csv) {
  const lines = csv.trim().split('\n');
  const result = [];
  let headers = lines[0].split(',');

  headers = headers.map((o) => o.trim());

  lines.map((line) => {
    const obj = {};
    const currentline = line.split(',');
    for (const j in headers) {
      obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  });
  result.shift();
  return result;
}
