import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Tag,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined, DeleteOutlined } from '@ant-design/icons';

import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  VehicleSelectors,
  AuthSelectors,
  LoadingSelectors,
  FuelSelectors,
  BrandSelectors,
  ModelSelectors,
} from '../../redux/reducers';
import { FuelActions, BrandActions, ModelActions } from '../../redux/actions';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import VehicleServiceType, { VehicleServiceTypeArray } from '../../enum/vehicleServiceType';

class VehicleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        modelId: null,
        fuelId: null,
        plate: null,
        serviceType: 3,
        compartmentsAmount: null,
        automotiveServicesAmount: null,
        compartments: [],
        automotiveServices: [],
      },
      brandSearchParam: {
        name: null,
        limit: Number.MAX_SAFE_INTEGER,
        orderBy: 'name',
      },
      modelSearchParam: {
        name: null,
        limit: Number.MAX_SAFE_INTEGER,
        orderBy: 'name',
        brandId: null,
      },
      automotiveService: null,
    };
  }

  componentDidMount() {
    const {
      getFuelSelectives, getBrandSelectives,
    } = this.props;
    const { brandSearchParam } = this.state;

    getFuelSelectives();
    getBrandSelectives(brandSearchParam);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      details,
      id,
    } = this.props;
    const { modelSearchParam: prevModelSearchParam } = this.state;

    if (details && nextProps.details && id !== 'add') {
      details.brandId = details.model.brand.id;
      details.compartmentsAmount = details.compartments.length;
      this.setState({ form: details });
    }

    if (details && !this.state.form.modelId) {
      details.brandId = details.model.brand.id;
      const modelSearchParam = {
        ...prevModelSearchParam,
        brandId: details.brandId,
      };

      this.setState({ modelSearchParam });

      if (!prevModelSearchParam.brandId) {
        setTimeout(() => this.onSearchModel(), 500);
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      modelId: yup.string().required(),
      fuelId: yup.string().required(),
      plate: yup.string().required(),
      serviceType: yup.string().required(),
    });

    schema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else {
          const { details, submitFunction } = this.props;

          if (details) {
            submitFunction(details.id, form);
          } else {
            submitFunction(null, form);
          }
        }
      }).catch(() => I18n.t('shared.genericError'));
  }

  onSearchBrand(name) {
    const { getBrandSelectives } = this.props;
    const { prevBrandSearchParam } = this.state;
    this.setState({ brandSearchParam: { ...prevBrandSearchParam, name } });
    getBrandSelectives({ ...this.state.brandSearchParam, withoutLoading: true });
  }

  onSearchModel(name) {
    const { getModelSelectives } = this.props;
    const { modelSearchParam: prevModelSearchParam } = this.state;
    this.setState({ modelSearchParam: { ...prevModelSearchParam, name } });
    getModelSelectives({ ...this.state.modelSearchParam, withoutLoading: true });
  }


  fieldChange(name, value) {
    const { form, modelSearchParam: prevModelSearchParam } = this.state;
    form[name] = value;
    this.setState({ form });

    if (name === 'brandId') {
      const modelSearchParam = {
        ...prevModelSearchParam,
        brandId: value,
      };

      this.setState({ modelSearchParam });
      setTimeout(() => this.onSearchModel(), 500);
    }

    if (name === 'compartmentsAmount') {
      const compartments = new Array(value).fill({
        capacityInLiters: null,
        fuelId: null,
      });

      form.compartments = compartments;
      this.setState({ form });
    }
  }

  compartmentChange(index, name, value) {
    const { form } = this.state;

    const compartment = form.compartments[index];
    form.compartments[index] = {
      ...compartment,
      ...(name && name === 'capacityInLiters' && { capacityInLiters: value }),
      ...(name && name === 'fuelId' && { fuelId: value }),
    };

    this.setState({ form });
  }

  addAutomotiveService() {
    const { form, automotiveService } = this.state;

    form.automotiveServices.push({ description: automotiveService });

    this.setState({ form, automotiveService: '' });
  }

  removeAutomotiveService(index) {
    const { form } = this.state;

    form.automotiveServices.splice(form.automotiveServices.findIndex((o, i) => i === index), 1);
    form.automotiveServices = [].concat(form.automotiveServices);

    this.setState({ form });
  }

  render() {
    const {
      loading,
      fuelSelectives,
      brandSelectives,
      modelSelectives,
    } = this.props;
    const { form, automotiveService } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={24}>
                <Col span={12}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.brand.label')}
                    onChange={(val) => this.fieldChange('brandId', val)}
                    options={brandSelectives}
                    value={form && form.brandId}
                    onSearch={(value) => this.onSearchBrand(value)}
                  />
                </Col>

                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.plate.label')}
                    value={form && form.plate}
                    onChange={(val) => this.fieldChange('plate', val)}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.model.label')}
                    onChange={(val) => this.fieldChange('modelId', val)}
                    options={modelSelectives}
                    value={form && form.modelId}
                    onSearch={(value) => this.onSearchModel(value)}
                    disabled={!form.brandId}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.fuel.label')}
                    onChange={(val) => this.fieldChange('fuelId', val)}
                    options={fuelSelectives}
                    value={form && form.fuelId}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.serviceType.label')}
                    onChange={(val) => this.fieldChange('serviceType', val)}
                    options={VehicleServiceTypeArray}
                    value={form && form.serviceType}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.color.label')}
                    value={form && form.color}
                    onChange={(val) => this.fieldChange('color', val)}
                  />
                </Col>
              </Row>

              {form && (form.serviceType === VehicleServiceType.FUEL_SUPPLY || form.serviceType === VehicleServiceType.ALL) && (
                <Row
                  className="mt-5"
                  gutter={16}
                >
                  <Col span={24}>
                    <h2>Abastecimento</h2>

                    <Divider />

                    <Row
                      className="mt-3"
                      gutter={16}
                    >
                      <Col span={6}>
                        <AdvancedSelect
                          label={I18n.t('routes.panel.administration.items.vehicle.details.form.compartmentsAmount.label')}
                          onChange={(val) => this.fieldChange('compartmentsAmount', val)}
                          options={[1, 2, 3, 4, 5].map((value) => ({ id: value, name: value }))}
                          value={form && form.compartmentsAmount}
                        />
                      </Col>
                      <Col span={6} />
                      <Col span={12}>
                        {form.compartmentsAmount && new Array(form.compartmentsAmount).fill().map((e, i) => (
                          <>
                            <Row gutter={16}>
                              <h2>Compartimento { i + 1}</h2>
                              <Row
                                className="mt-4"
                                gutter={16}
                              >
                                <Col span={12}>
                                  <AdvancedInput
                                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.capacity.label')}
                                    value={form && form.compartments[i] && form.compartments[i].capacityInLiters}
                                    onChange={(val) => this.compartmentChange(i, 'capacityInLiters', val)}
                                  />
                                </Col>
                                <Col span={12}>
                                  <AdvancedSelect
                                    label={I18n.t('routes.panel.administration.items.vehicle.details.form.fuel.label')}
                                    onChange={(val) => this.compartmentChange(i, 'fuelId', val)}
                                    options={fuelSelectives}
                                    value={form && form.compartments[i] && form.compartments[i].fuelId}
                                  />
                                </Col>
                              </Row>

                              <Divider />
                            </Row>
                          </>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}

              {form && (form.serviceType === VehicleServiceType.AUTOMOTIVE_SERVICE || form.serviceType === VehicleServiceType.ALL) && (
                <Row
                  className="mt-5"
                  gutter={16}
                >
                  <Col span={24}>
                    <h2>Serviços automotivos</h2>

                    <Divider />

                    <Row
                      className="mt-3"
                      gutter={16}
                    >
                      <Col span={8}>
                        <AdvancedInput
                          value={automotiveService}
                          onChange={(val) => this.setState({ automotiveService: val })}
                        />
                      </Col>
                      <Col
                        span={4}
                        className="mt-1"
                      >
                        <AdvancedButton
                          type="button"
                          text={I18n.t('shared.addButtonText')}
                          onClick={() => this.addAutomotiveService()}
                        />
                      </Col>
                    </Row>

                    <div className="d-flex flex-row mt-3">
                      {form && form.automotiveServices && form.automotiveServices.map((o, i) => (
                        <>
                          <Tag
                            color="#01A56D"
                            className="mr-3 p-2"
                          >
                            <span className="mt-2">{ o.description }</span>
                            <DeleteOutlined
                              className="vehicle-details__automotive-service-tag__delete"
                              onClick={() => this.removeAutomotiveService(i)}
                            />
                          </Tag>
                        </>
                      ))}
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.administration.items.vehicle.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: VehicleSelectors.getDetails(state),
  fuelSelectives: FuelSelectors.getSelectives(state),
  brandSelectives: BrandSelectors.getSelectives(state),
  modelSelectives: ModelSelectors.getSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getFuelSelectives: () => dispatch(FuelActions.getSelectives()),
  getBrandSelectives: (params) => dispatch(BrandActions.getSelectives(params)),
  getModelSelectives: (params) => dispatch(ModelActions.getSelectives(params)),
});


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleForm);
