import React from 'react';
import { Translate } from 'react-redux-i18n';

export const VehicleType = {
  CUSTOMER: 1,
  VERSA: 2,
};

export const VehicleTypeArray = [
  { id: VehicleType.CUSTOMER, name: <Translate value="enum.vehicleType.customer" /> },
  { id: VehicleType.VERSA, name: <Translate value="enum.vehicleType.versa" /> },
];

export const GetVehicleTypeTitle = (value) => (VehicleTypeArray.find((o) => o.id === value).name);
