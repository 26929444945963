import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import {
  OrderSelectors, AuthSelectors, LoadingSelectors, DriverSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedAutoCompleteInput from '../../../components/shared/AdvancedAutoCompleteInput';
import { DriverActions } from '../../redux/actions';
import OrderStatus from '../../enum/orderStatusType';

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        orderStatus: null,
        driverId: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          orderStatus: details && details.orderStatus,
          driverId: details && details.driverId,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      driverId: yup.string().required(),
    });

    schema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else {
          const { details, submitFunction } = this.props;

          if (details) {
            submitFunction(details.id, form);
          } else {
            submitFunction(null, form);
          }
        }
      }).catch(() => I18n.t('shared.genericError'));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading,
      getAvailableDrivers,
      details,
    } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <AdvancedAutoCompleteInput
                    searchMethod={(params) => getAvailableDrivers(params)}
                    data={I18n.t('advancedAutoComplete.driver')}
                    onSelect={(item) => this.fieldChange('driverId', item.id)}
                    value={(details && details.driver && details.driver.name) || (form && form.driverId) || ''}
                    hideSearch
                  />
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.administration.items.order.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                    disabled={details && details.orderStatus !== OrderStatus.PAIED}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: OrderSelectors.getDetails(state),
  driverAvailables: DriverSelectors.getAvailables(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAvailableDrivers: (params, callback) => dispatch(DriverActions.getAvailables(params, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderForm);
