import {
  ACTION_SAVE_MODEL_SELECTIVES,
} from '../actions/model';

const initialState = {
  selectives: null,
};

export default function model(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_MODEL_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getSelectives(state) {
  return state.model.selectives;
}
