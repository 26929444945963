export default {
  localhost: 'https://api.versafuel.com.br/api',
  development: 'https://apidev.versafuel.com.br/api',
  production: 'https://api.versafuel.com.br/api',

  localhostAuth: 'https://auth.versafuel.com.br/api',
  developmentAuth: 'https://auth.versafuel.com.br/api',
  productionAuth: 'https://auth.versafuel.com.br/api',

  authApiKey: '41175782-0462-400b-a75c-fdc0622a44e8',
  authApiKeyDev: '86c78e88-454c-4428-a6fe-f5a578f636ac',
};
