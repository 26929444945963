import {
  ACTION_SAVE_ORDER_PAGINATED,
  ACTION_SAVE_ORDER_DETAILS,
  ACTION_SAVE_ORDER_COUNT,
  ACTION_SAVE_ORDER_ABSOLUTE_VALUES,
  ACTION_SAVE_TIPS_PAGINATED,
  ACTION_SAVE_STATES_SELECTIVES,
} from '../actions/order';

const initialState = {
  paginatedData: null,
  details: null,
  count: null,
  absoluteValues: null,
  tipsPaginated: null,
  selectives: null,
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_ORDER_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    case ACTION_SAVE_TIPS_PAGINATED:
      state = {
        ...state,
        tipsPaginated: action.payload,
      };
      return state;
    case ACTION_SAVE_ORDER_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    case ACTION_SAVE_ORDER_COUNT:
      state = {
        ...state,
        count: action.payload,
      };
      return state;
    case ACTION_SAVE_ORDER_ABSOLUTE_VALUES:
      state = {
        ...state,
        absoluteValues: action.payload,
      };
      return state;
    case ACTION_SAVE_STATES_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.order.paginatedData;
}

export function getTipsPaginated(state) {
  return state.order.tipsPaginated;
}

export function getDetails(state) {
  return state.order.details;
}

export function getCount(state) {
  return state.order.count;
}

export function getAbsoluteValues(state) {
  return state.order.absoluteValues;
}

export function getSelectives(state) {
  return state.order.selectives;
}
