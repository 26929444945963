import React from 'react';
import { Translate } from 'react-redux-i18n';

export const Reasons = {
  OFFSITE: 1,
  TECHNICAL_PROBLEMS: 2,
  INABILITY_PARK: 3,
  SUPPLY_RESTRICTION: 4,
  CLOSED_TANK: 5,
  OTHER: 6,
};

export const ReasonsTypeArray = [
  { id: Reasons.OFFSITE, name: <Translate value="enum.reasonsTypes.offSite" /> },
  { id: Reasons.TECHNICAL_PROBLEMS, name: <Translate value="enum.reasonsTypes.technicalProblems" /> },
  { id: Reasons.INABILITY_PARK, name: <Translate value="enum.reasonsTypes.inabilityPark" /> },
  { id: Reasons.SUPPLY_RESTRICTION, name: <Translate value="enum.reasonsTypes.supplyRestriction" /> },
  { id: Reasons.CLOSED_TANK, name: <Translate value="enum.reasonsTypes.closedTank" /> },
  { id: Reasons.OTHER, name: <Translate value="enum.reasonsTypes.other" />, },
];

export const GetReasonsTypeTitle = (value) => (ReasonsTypeArray.find((o) => o.id === value).name);
