import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

import {
  addLoading,
  removeLoading,
} from './loading';
import SettingsRequests from '../../api/settings';

export const ACTION_SAVE_SETTINGS_SELECTIVES = 'ACTION_SAVE_SETTINGS_SELECTIVES';

export const ACTION_CLEAN_SETTINGS_SELECTIVES = 'ACTION_CLEAN_SETTINGS_SELECTIVES';

// cleaners
export const cleanSelectives = () => ({
  type: ACTION_CLEAN_SETTINGS_SELECTIVES,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getSelectives = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_SETTINGS_SELECTIVES,
      payload: null,
    });
    response = await SettingsRequests.getSelectives();
    dispatch({
      type: ACTION_SAVE_SETTINGS_SELECTIVES,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await SettingsRequests.update(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
