const initialState = {
  paginatedData: null,
  details: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
