import {
  ACTION_SAVE_FUEL_PAGINATED,
  ACTION_SAVE_FUEL_DETAILS,
  ACTION_SAVE_FUEL_SELECTIVES,
} from '../actions/fuel';

const initialState = {
  paginatedData: null,
  details: null,
  selectives: null,
};

export default function fuel(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_FUEL_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    case ACTION_SAVE_FUEL_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    case ACTION_SAVE_FUEL_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.fuel.paginatedData;
}

export function getDetails(state) {
  return state.fuel.details;
}

export function getSelectives(state) {
  return state.fuel.selectives;
}
