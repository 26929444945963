import React from 'react';
import { useLocation } from '@reach/router';
import {
  Divider,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Icon,
  Collapse,
  Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { SearchOutlined, FileImageOutlined } from '@ant-design/icons';
import DataTable from '../../../../components/shared/DataTable';
import { AuthActions, FuelSupplyActions } from '../../../../app/redux/actions';
import { AuthSelectors, LoadingSelectors, FuelSupplySelectors } from '../../../../app/redux/reducers';
import { getFuelSupplyTypeTitle } from '../../../../app/enum/fuelSupplyType';
import MoneyFormat from '../../../../components/shared/MoneyFormat';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import Loading from '../../../../components/shared/Loading/Loading';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import * as DateUtils from '../../../../app/utils/date';
import QueryStringUtils from '../../../../app/utils/queryString';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  vehiclePlate: null,
};

class FuelSupplyList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
      loadingPage: null,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params = {} } = qs.get();

    const url = new URL(window.location.href);

    if (url.searchParams.get('vehiclePlate')) {
      this.setState({ loadingPage: true });
      params.vehiclePlate = url.searchParams.get('vehiclePlate');
    }

    if (params) {
      this.setState({ params });
      setTimeout(() => {
        this.getPaginatedDataAndRefreshTable();
        this.setState({ loadingPage: false });
      }, 400);
    }
  }

  getPaginatedData(params) {
    const { me } = this.props;
    const { getPaginated } = this.props;

    getPaginated(params, me);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.getPaginatedData(params);

      if (this.dataTableRef.current) {
        this.dataTableRef.current.reset(false);
      }
    }
  }

  useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  urlHasQueryString() {
    const url = window.location.href;
    return url.includes('=');
  }

  render() {
    const { Content } = Layout;
    const { params, loadingPage } = this.state;

    const {
      fuelSuppliesPaginated,
      loading,
    } = this.props;

    return loadingPage ? (<Loading />) : (
      <div>

        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.fuelSupply.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.administration.items.fuelSupply.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={24}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.fuelSupply.advancedFilter.plate.label')}
                    onChange={(val) => this.fieldChange('vehiclePlate', val)}
                    value={params && params.vehiclePlate}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="administrators">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.fuelSupply.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.fuelSupply.pageTitle')}
                </h2>
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={fuelSuppliesPaginated}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.vehicle.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.vehicle.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.compartment.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.compartment.title'),
                      render: (value, row) => (row.compartment
                        ? `${row.compartment.capacityInLiters}L (${ row.compartment.fuel && row.compartment.fuel.name ?  row.compartment.fuel.name : '--'})` : '--'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.driver.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.driver.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.type.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.type.title'),
                      render: (value) => getFuelSupplyTypeTitle(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.liters.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.liters.title'),
                      render: (value) => value ?
                        (
                          <MoneyFormat
                            value={value}
                            precision={2}
                        />
                        ): '--'
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.totalValue.key'),
                      title: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.totalValue.title'),
                      render: (value) => {
                        if (value) {
                          return (
                            <MoneyFormat
                              value={value}
                              precision={2}
                              isMoney
                            />
                          );
                        }
                        return '--';
                      },
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.evidenceImageName.key'),
                      title: '',
                      render: (value) => (
                        <Tooltip title={I18n.t('routes.panel.administration.items.fuelSupply.dataTable.columns.evidenceImageName.tooltip')}>
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FileImageOutlined />
                          </a>
                        </Tooltip>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fuelSuppliesPaginated: FuelSupplySelectors.getReport(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters, me) => dispatch(FuelSupplyActions.getReport(parameters, me)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuelSupplyList);
