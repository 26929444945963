import './styles/less/index.less';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';
import { ConfigProvider, message } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
  I18n,
} from 'react-redux-i18n';

import Storage from './app/services/storage';

import Config from './app/config/app.config';

import AuthServices from './app/services/auth';
import i18nDictionary from './i18n';
import * as AuthActions from './app/redux/actions/auth';
import * as OrderActions from './app/redux/actions/order';
import initalizeRedux from './app/redux';
import Routes from './app/routes';
import CashSuccess from './assets/sounds/cash-success.mp3';
import WarnSound from './assets/sounds/warn.mp3';

const redux = initalizeRedux();

export function axiosHandleUnatorizedErr() {
  redux.dispatch(AuthActions.cleanAuth());
}

async function authenticated(store) {
  if (AuthServices.isAuthenticated()) {
    const auth = AuthServices.get();
    await store.dispatch(AuthActions.saveAuthentication(auth));
  }
}

async function createOrderNotifications() {
  setInterval(() => {
    if (AuthServices.isAuthenticated()) {
      redux.dispatch(OrderActions.getCount(null, (response) => {
        const ordersCount = Storage.get('ordersCount');
        if (ordersCount && ordersCount < response) {
          try {
            redux.dispatch(OrderActions.getPaginated({}));
          } catch (err) {
            // no handle
          }
          const cashSound = new Audio(CashSuccess);
          cashSound.play();
          message.success(I18n.t('shared.notifications.orderReceived'));
        }
        Storage.set('ordersCount', response);
      }));
    }
  }, 15000);
}

async function createOrderProblemNotifications() {
  setInterval(() => {
    if (AuthServices.isAuthenticated()) {
      try {
        redux.dispatch(OrderActions.getCount({ problem: true }, (response) => {
          const orderProblemCount = Storage.get('orderProblemCount');
          if (orderProblemCount && orderProblemCount < response) {
            redux.dispatch(OrderActions.getPaginated({}));
            const warnSound = new Audio(WarnSound);
            warnSound.play();
            message.warn(I18n.t('shared.notifications.orderProblemReceived'));
          }
          Storage.set('orderProblemCount', response);
        }));
      } catch (err) {
        // no handle
      }
    }
  }, 15000);
}

try {
  const tz = jstz.determine();
  const timezone = tz.name() || Config.timezone;
  let { language } = Config.language;
  if (!i18nDictionary[language]) ({ language } = Config);

  // initialize react-redux-i18n
  syncTranslationWithStore(redux);
  redux.dispatch(loadTranslations(i18nDictionary));
  redux.dispatch(setLocale(language));

  moment.tz.setDefault(timezone);
  moment.locale(language);

  authenticated(redux);
  createOrderNotifications();
  createOrderProblemNotifications();
} catch (err) {
  // Redirect to error page
}

ReactDOM.render(
  <Provider store={redux}>
    <ConfigProvider locale={ptBR}>
      <Routes />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
