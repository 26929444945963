import React from 'react';
import { Translate } from 'react-redux-i18n';

export const ProfileType = {
  ADMIN: 1,
  USER: 2,
  DRIVER: 3,
};

export const ProfileTypeArray = [
  { id: ProfileType.ADMIN, name: <Translate value="enum.profileType.admin" /> },
  { id: ProfileType.USER, name: <Translate value="enum.profileType.user" /> },
  { id: ProfileType.DRIVER, name: <Translate value="enum.profileType.driver" /> },
];

export const GetProfileTypeTitle = (value) => (ProfileTypeArray.find((o) => o.id === value).name);
