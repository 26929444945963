export default {
  application: {
    name: 'Versa - Dashboard',
    shortDescription: 'Versa',
    title: 'Versa',
    phoneCountryCode: '55',
    version: {
      title: 'Versão',
      number: '1.1.0',
    },
    footer: {
      poweredBy: 'Powered by',
      poweredByBrand: 'mb.labs',
      poweredByBrandUrl: 'https://mblabs.com.br',
    },
  },
  shared: {
    phoneMask: '(99) 9 9999-9999',
    moneyPrefix: 'R$',
    decimalSeparator: ',',
    thousandSeparator: '.',
    notFoundSearch: 'Nada encontrado.',
    selectSomeValue: 'Selecione...',
    typeToContinue: 'Digite algo para pesquisar...',
    type: 'Digite...',
    cancel: 'Cancelar',
    close: 'Fechar',
    useValues: 'Utilizar selecionados',
    yes: 'Sim',
    no: 'Não',
    loading: 'Carregando...',
    confirmTitle: 'Você tem certeza?',
    search: 'Pesquisar',
    logout: {
      menuText: 'Sair do sistema',
      confirmation: 'Você tem certeza que deseja sair?',
      confirmButton: 'Tenho certeza, sair.',
    },
    advancedFilter: {
      searchButtonText: 'Pesquisar',
      cleanButtonText: 'Limpar filtros',
    },
    nothingToShow: 'Nada para exibir, verifique os parâmetros e tente novamente.',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
    distanceUnit: 'metros',
    distanceUnitSmall: 'm',
    cantAccess: 'Você não tem permissão para visualizar esta página.',
    searchSomething: 'Digite algo para pesquisar...',
    dateMask: 'DD/MM/YYYY',
    advancedFilters: {
      title: 'Filtros avançados',
      rangeLabel: 'Período',
      userOneLabel: 'Usuário 1 (nome ou registro)',
      userTwoLabel: 'Usuário 2 (nome ou registro)',
      loginLabel: 'Registro 1',
      loginTwoLabel: 'Registro 2',
      filterButtonText: 'Filtrar',
      exportButtonText: 'Exportar',
      clearButtonText: 'Limpar filtros',
      rangeStart: 'Data inicial',
      rangeEnd: 'Data final',
    },
    saveButtonText: 'Salvar',
    backButtonText: 'Voltar',
    addButtonText: 'Adicionar',
    genericError: 'Algo deu errado. Tente novamente mais tarde.',
    genericFieldsError: 'Verifique os campos e tente novamente.',
    notifications: {
      orderReceived: 'Um novo pedido foi identificado.',
      orderProblemReceived: 'Um novo pedido com problema foi identificado.',
    },
    errors: {
      fuel_already_registered: 'Nome de combustível já existente.',
      invalid_price: 'Valor inválido.',
      vehicle_already_exists: 'Veículo já existente.',
      invalid_color: 'Digite uma cor para o veículo.',
      invalid_password: 'Senha inválida.',
      driver_unavailable_for_scheduling: 'Motorista selecionado está em outro atendimento.',
      driver_not_available: 'Motorista não encontrado.',
    },
  },
  routes: {
    login: {
      url: '/',
      pageTitle: 'Entrar',
      content: {
        header: 'Entre com o e-mail e senha cadastrados',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        password: 'Senha',
        passwordPlaceholder: 'Sua senha segura',
        company: 'Empresa',
        companyAll: 'Todas',
        continueButton: 'Entrar no sistema',
        welcome: 'Bem vindo(a) novamente.',
        forgotPassword: 'Esqueci minha senha',
        subHeader: 'Entre com seu e-mail e senha cadastrados',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        invalid_credentials: 'Credenciais inválidas, verifique e tente novamente.',
        user_not_found: 'Credenciais inválidas, verifique e tente novamente.',
        user_no_access: 'Você não tem acesso a essa tela. Entre em contato com um administrador.',
        user_invalid_token: 'Token inválido ou expirado. Entre novamente.',
      },
      messages: {
        welcome: 'Bem vindo(a) novamente.',
      },
    },

    forgotPassword: {
      url: '/esqueci-minha-senha',
      pageTitle: 'Esqueci minha senha',
      content: {
        header: 'Entre com o e-mail cadastrado',
        subHeader: 'Iremos enviar os próximos passos no e-mail abaixo para prosseguir com a recuperação de sua senha',
        email: 'E-mail',
        emailPlaceholder: 'Seu e-mail cadastrado',
        continueButton: 'Enviar e-mail',
        forgotPassword: 'Esqueci minha senha',
        backToLogin: 'Voltar ao login',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        user_not_found: 'Usuário não encontrado',
      },
      messages: {
        emailSent: 'E-mail de recuperação enviado (verifique também a caixa de spam).',
      },
    },

    forgotPasswordCode: {
      url: '/esqueci-minha-senha/definir',
      pageTitle: 'Definir nova senha',
      content: {
        header: 'Entre com o código recebido por e-mail',
        subHeader: 'Insira abaixo o código recebido por e-mail',
        email: 'E-mail',
        emailPlaceholder: 'E-mail',
        code: 'Código recebido por e-mail',
        codePlaceholder: 'Código recebido',
        continueButton: 'Alterar senha',
        notReceivedEmail: 'Não recebi o e-mail',
        passwordChanged: 'Senha alterada com sucesso.',
        password: 'Nova senha',
        passwordPlaceholder: 'Senha segura',
        passwordConfirm: 'Confirme a senha acima',
        passwordConfirmPlaceHolder: 'Confirmação',
        subHeaderPassword: 'Insira uma nova senha no campo abaixo, e também a sua confirmação',
        cancelButton: 'Cancelar',
      },
      errors: {
        fields: 'Verifique os campos e tente novamente',
        passwordMismatch: 'As senhas não conferem',
        passwordNotSecure: 'Senha deve ter pelo menos 6 carateres',
        invalid_send_to: 'Parâmetros inválidos',
      },
      messages: {
        validCode: 'Código válido, prossiga',
        passwordChanged: 'Senha alterada, entre com suas novas credenciais',
      },
    },

    panel: {
      pageTitle: 'Painel',

      administration: {
        sidebarVisible: 'y',
        sidebarTitle: 'Administrativo',
        icon: 'lock',
        items: {
          administrator: {
            url: '/painel/administrativo/administradores',
            sidebarTitle: 'Administradores',
            pageTitle: 'Gerenciar administradores',
            icon: 'lock',
            addNewButtonText: 'Adicionar administrador',
            pageTitleAdd: 'Adicionar administrador',
            pageTitleEdit: 'Editar administrador',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um administrador',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um administrador',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do administrador',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
              messages: {
                successCreate: 'Administrador adicionado com sucesso.',
                successEdit: 'Administrador atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          user: {
            url: '/painel/administrativo/usuarios',
            sidebarTitle: 'Usuários',
            pageTitle: 'Gerenciar usuários',
            icon: 'user',
            addNewButtonText: 'Adicionar usuário',
            pageTitleAdd: 'Adicionar usuário',
            pageTitleEdit: 'Editar usuário',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um usuário',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um usuário',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              name: {
                key: 'name',
                placeholder: 'Digite o nome...',
                label: 'Nome',
              },
              email: {
                key: 'email',
                label: 'E-mail',
              },
              cellphone: {
                key: 'cellphone',
                label: 'Telefone',
              },
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do usuário',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                cellphone: {
                  label: 'Telefone',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
              },
              messages: {
                successCreate: 'Usuário adicionado com sucesso.',
                successEdit: 'Usuário atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                cellphone: 'Telefone inválido, verifique e tente novamente.',
                cellphone_already_registerd: 'O telefone inserido já está em uso, verifique e tente novamente.',
                email_already_used: 'O e-mail inserido já está em uso, verifique e tente novamente.',
                email_already_registerd: 'O e-mail inserido já está em uso, verifique e tente novamente.',
                emailAlreadyInUse: 'O e-mail inserido já está em uso, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
                invalid_password: 'Senha inválida.',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                cellphone: {
                  key: 'cellphone',
                  title: 'Telefone',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },

          order: {
            url: '/painel/administrativo/pedidos',
            sidebarTitle: 'Pedidos',
            pageTitle: 'Gerenciar pedidos',
            icon: 'retweet',
            exportCsvButtonText: 'Exportar CSV',
            updateStatus: 'Atualizar status',
            lastUpdate: 'Última atualização - ',
            addNewButtonText: 'Adicionar pedido',
            pageTitleAdd: 'Adicionar pedido',
            pageTitleEdit: 'Visualizar pedido',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um pedido',
            pageDescriptionEdit: 'Selecione o motorista responsável pelo pedido no campo abaixo',
            disableAddButton: 'y',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
              exportCsvSuccess: 'O relatório foi exportado com sucesso e em breve estará disponível em seu e-mail.',
              cancelSuccess: 'O pedido foi cancelado com sucesso!',
            },
            errors: {
              exportCsvError: 'Ocorreu um erro ao gerar o relatório. Tente novamente mais tarde.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              insertValue: 'Insira um valor mínimo e máximo',
              minValue: {
                key: 'minValue',
                label: 'Mínimo R$500',
              },
              maxValue: {
                key: 'maxValue',
                label: 'Máximo R$20.000',
              },              orderNumber: {
                key: 'orderNumber',
                label: 'Número do pedido',
              },
              fuelId: {
                key: 'fuelId',
                label: 'Combustível',
              },
              orderStatus: {
                key: 'orderStatus',
                label: 'Status do pedido',
              },
              deliveryType: {
                key: 'deliveryType',
                label: 'Tipo da entrega',
              },
              driverId: {
                key: 'driverId',
                label: 'Motorista',
              },
              // location
              state: {
                key: 'state',
                label: 'Estado',
              },
              city: {
                key: 'city',
                label: 'Cidade',
              },
              neighborhood: {
                key: 'neighborhood',
                label: 'Bairro',
              },
              street: {
                key: 'street',
                label: 'Rua',
              },
              zipCode: {
                key: 'zipCode',
                label: 'CEP',
              },
              createdAtStart: {
                key: 'createdAtStart',
                label: 'Data início do intervalo',
              },
              createdAtEnd: {
                key: 'createdAtEnd',
                label: 'Data final do intervalo',
              },
            },
            details: {
              form: {
                driverId: {
                  label: 'Motorista',
                },
              },
              values: {
                title: 'Detalhes do pedido #',
                acceptedAt: 'Data de aceite',
                isFullTank: 'Tanque cheio?',
                createdAt: 'Data do pedido',
                deliveryForecastDate: 'Previsão',
                deliveryFinishedAt: 'Finalização',
                lateOrder: ' (atrasado)',
                deliveryFinishedAtNull: 'Em andamento',
                reasonCancellation: 'Motivo do cancelamento',
                location: 'Localização',
                addressType: 'Endereço salvo',
                client: 'Cliente',
                fuel: 'Combustível',
                requestedLiters: 'Litros solicitados',
                liters: 'litros',
                payment: 'Valor pago',
                deliveryFee: 'Taxa de entrega',
                totalAmount: 'Total do pedido',
                deliveryType: 'Tipo de entrega',
                orderStatus: 'Status do pedido',
                problemType: 'Tipo do problema',
                problemMessage: 'Mensagem',
                orderImages: 'Fotos',
                vehicle: 'Veículo',
                complement: 'Complemento',
                seeOnMap: 'Ver no mapa',
                seeClient: 'Ver detalhes',
                clientEvaluation: 'Avaliação do cliente',
                clientConsideration: 'Considerações do cliente',
                deliveredLiters: 'Litros abastecidos',
                tipAmount: 'Valor da gorjeta',
                refundAmount: 'Valor estornado',
                previousPayment: 'Valor pago antes do cancelamento',
                hasCancellationFee: 'Possui taxa de cancelamento',
                driverScheduleDate: 'Data de agendamento',
                canceledBy: 'Cancelado por',
                admin: 'Versa',
                customer: 'Cliente',
                invoiceRequested: 'Nota Fiscal',
                invoiceDocument: 'CPF/CNPJ',
                justification: 'Justificativa do cancelamento',
              },
              messages: {
                successEdit: 'Pedido atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                invalidMinValue: 'O valor mínimo é R$500,00',
                invalidMaxValue: 'O valor máximo é R$20.000,00',
                invalidValue: 'O valor máximo deve ser maior que o mínimo',
              },
            },
            dataTable: {
              columns: {
                orderNumber: {
                  key: 'orderNumber',
                  title: 'Pedido',
                },
                location: {
                  key: 'location',
                  title: 'Localização',
                },
                street: {
                  key: 'street',
                  title: 'Rua',
                },
                invoiceDocument: {
                  key: 'invoiceDocument',
                  title: 'Nota Fiscal',
                },
                requestedLiters: {
                  key: 'requestedLiters',
                  title: 'Litros solicitados',
                },
                fuel: {
                  key: 'fuel.name',
                  title: 'Combustível',
                },
                deliveryType: {
                  key: 'deliveryType',
                  title: 'Tipo de entrega',
                },
                orderStatus: {
                  key: 'orderStatus',
                  title: 'Status',
                },
                driverSchedule: {
                  key: 'driverSchedule_startAt',
                  title: 'Agendamento',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data do pedido',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  cancelText: 'Cancelar',
                },
                totalAmount: {
                  key: 'orderPayment_totalAmount',
                  title: 'Valor Total',
                },
                hasRefund: {
                  key: 'orderPayment_hasRefund',
                  title: 'Possuí Reembolso',
                },
                refundAmount: {
                  key: 'orderPayment_refundAmount',
                  title: 'Quantia de Reembolso',
                },
              },
            },
            modal: {
              title: 'Deseja realmente cancelar?',
              reasonTitle: 'Descreva abaixo',
              cancelText: 'Não',
              okText: 'Sim',
              reason: 'reason',
              reasonOptions: 'Motivo do cancelamento',
            },
          },

          driverRating: {
            url: '/painel/administrativo/avaliacoes',
            sidebarTitle: 'Gorjeta de motoristas',
            pageTitle: 'Gorjeta de motoristas',
            icon: 'star',
            exportCsvButtonText: 'Exportar CSV',
            messages: {
              exportCsvSuccess: 'O relatório foi exportado com sucesso e em breve estará disponível em seu e-mail.',
            },
            errors: {
              exportCsv: 'Ocorreu um erro ao exportar em CSV.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              orderNumber: {
                key: 'orderNumber',
                label: 'Número do pedido',
              },
              driverId: {
                key: 'driverId',
                label: 'Motorista',
              },
              createdAtStart: {
                key: 'createdAtStart',
                label: 'Data início do intervalo',
              },
              createdAtEnd: {
                key: 'createdAtEnd',
                label: 'Data final do intervalo',
              },
            },
            details: {
              form: {
                driverId: {
                  label: 'Motorista',
                },
              },
            },
            dataTable: {
              columns: {
                orderNumber: {
                  key: 'orderNumber',
                  title: 'Pedido',
                },
                clientEvaluation: {
                  key: 'clientEvaluation',
                  title: 'Avaliação',
                },
                tipAmount: {
                  key: 'orderPayment.tipAmount',
                  title: 'Gorjeta',
                },
                driver: {
                  key: 'driver',
                  title: 'Motorista',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data do pedido',
                },
              },
            },
          },

          driver: {
            url: '/painel/administrativo/motoristas',
            sidebarTitle: 'Motoristas',
            pageTitle: 'Gerenciar motoristas',
            icon: 'car',
            addNewButtonText: 'Adicionar motorista',
            pageTitleAdd: 'Adicionar motorista',
            pageTitleEdit: 'Editar motorista',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um motorista',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um motorista',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            details: {
              changePasswordPhrase: 'Desejo alterar a senha do motorista',

              form: {
                name: {
                  label: 'Nome completo',
                },
                email: {
                  label: 'E-mail',
                },
                password: {
                  label: 'Senha',
                },
                passwordConfirmation: {
                  label: 'Confirmação de senha',
                },
                weekScheduler: {
                  icon: 'schedule',
                  titleLabel: 'Horário de trabalho',
                  descriptionLabel: 'Preencha a agenda de acordo com os horários disponíveis de trabalho do motorista',
                },
              },
              messages: {
                successCreate: 'Motorista adicionado com sucesso.',
                successEdit: 'Motorista atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
                invalid_password: 'A senha escolhida é inválida.',
                invalid_name: 'O nome escolhido é inválido',
              },
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                email: {
                  key: 'email',
                  title: 'E-mail',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },

          fuel: {
            url: '/painel/administrativo/combustiveis',
            sidebarTitle: 'Combustíveis',
            pageTitle: 'Gerenciar combustíveis',
            icon: 'fire',
            addNewButtonText: 'Adicionar combustível',
            pageTitleAdd: 'Adicionar combustível',
            pageTitleEdit: 'Editar combustível',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um combustível',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um combustível',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            details: {

              form: {
                name: {
                  label: 'Nome',
                },
                price: {
                  label: 'Valor',
                },
                isAvailable: {
                  label: 'Está disponível?',
                },
              },
              messages: {
                successCreate: 'Combustível adicionado com sucesso.',
                successEdit: 'Combustível atualizado com sucesso.',
              },
              errors: {},
            },
            dataTable: {
              columns: {
                name: {
                  key: 'name',
                  title: 'Nome',
                },
                price: {
                  key: 'price',
                  title: 'Valor',
                },
                isAvailable: {
                  key: 'isAvailable',
                  title: 'Disponibilidade',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },

          vehicle: {
            url: '/painel/administrativo/veiculos',
            sidebarTitle: 'Veículos versa',
            pageTitle: 'Gerenciar veículos versa',
            icon: 'car',
            addNewButtonText: 'Adicionar veículo',
            pageTitleAdd: 'Adicionar veículo',
            pageTitleEdit: 'Editar veículo',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um veículo',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um veículo',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              plate: {
                key: 'plate',
                placeholder: 'Digite a placa...',
                label: 'Placa',
              },
              serviceType: {
                key: 'serviceType',
                placeholder: 'Tipo de serviço',
                label: 'Tipo de serviço',
              },
            },
            details: {
              form: {
                brand: {
                  label: 'Marca',
                },
                model: {
                  label: 'Modelo',
                },
                plate: {
                  label: 'Placa',
                },
                fuel: {
                  label: 'Tipo de combustível',
                },
                serviceType: {
                  label: 'Destinação do veículo',
                },
                color: {
                  label: 'Cor',
                },
                compartmentsAmount: {
                  label: 'Quantidade de compartimentos',
                },
                capacity: {
                  label: 'Capacidade',
                },
              },
              messages: {
                successCreate: 'Veículo adicionado com sucesso.',
                successEdit: 'Veículo atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
                email: 'E-mail inválido, verifique e tente novamente.',
                cellphone: 'Telefone inválido, verifique e tente novamente.',
                cellphoneAlreadyInUse: 'O telefone inserido já está em uso, verifique e tente novamente.',
                emailAlreadyInUse: 'O e-mail inserido já está em uso, verifique e tente novamente.',
                passwordsMismatch: 'A senha e confirmação de senha não estão iguais.',
                passwordsRequired: 'A senha e confirmação de senha são requeridas.',
              },
            },
            dataTable: {
              columns: {
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                plate: {
                  key: 'plate',
                  title: 'Placa',
                },
                modelName: {
                  key: 'model.name',
                  title: 'Modelo',
                },
                serviceType: {
                  key: 'serviceType',
                  title: 'Tipo de serviço',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  goToReportText: 'Ver relatório de abastecimentos',
                  removeText: 'Remover',
                },
              },
            },
          },
          fuelSupply: {
            url: '/painel/administrativo/abastecimentos',
            sidebarTitle: 'Abastecimentos',
            pageTitle: 'Relatório de abastecimentos versa',
            icon: 'dashboard',
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              plate: {
                key: 'plate',
                placeholder: 'Digite a placa...',
                label: 'Placa',
              },
              serviceType: {
                key: 'serviceType',
                placeholder: 'Tipo de serviço',
                label: 'Tipo de serviço',
              },
            },
            dataTable: {
              columns: {
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                vehicle: {
                  key: 'vehiclePlate',
                  title: 'Veículo (Placa)',
                },
                compartment: {
                  key: 'compartment',
                  title: 'Compartimento',
                },
                driver: {
                  key: 'driver.name',
                  title: 'Motorista',
                },
                type: {
                  key: 'type',
                  title: 'Tanque',
                },
                liters: {
                  key: 'liters',
                  title: 'Litros',
                },
                totalValue: {
                  key: 'totalValue',
                  title: 'Valor',
                },
                evidenceImageName: {
                  key: 'evidenceImageUrl',
                  tooltip: 'Visualizar foto',
                  title: 'Foto da evidência',
                },
              },
            },
          },
          availableRegion: {
            url: '/painel/administrativo/regioes_disponiveis',
            sidebarTitle: 'Regiões Disponíveis',
            pageTitle: 'Regiões Disponíveis',
            icon: 'global',
            addNewButtonText: 'Adicionar Região',
            pageTitleAdd: 'Adicionar Região',
            pageTitleEdit: 'Editar Região',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um região',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um região',
            modalMessage: 'Clique ou arraste e solte seu arquivo .csv aqui ',
            instructionModalOne: 'Clique aqui',
            instructionModalTwo: 'para realizar o download do modelo',
            buttonMessage: 'Upload CSV',
            messages: {
              addSuccess: 'Adicionado com sucesso.',
              editSuccess: 'Editado com sucesso.',
              deleteSuccess: 'Removido com sucesso.',
            },
            details: {
              form: {
                name: {
                  label: 'Nome da Região',
                },
                state: {
                  label: 'Estado',
                },
                city: {
                  label: 'Cidade',
                },
                uploadFile: {
                  label: 'Upload de arquivo',
                },
              },
              messages: {
                successCreate: 'Região adicionado com sucesso.',
              },
              errors: {},
            },
          },
        },
      },

      manage: {
        sidebarVisible: 'y',
        sidebarTitle: 'Gerenciamento',
        icon: 'appstore',
        items: {
          pushNotification: {
            url: '/painel/gerenciamento/notificacoes',
            sidebarTitle: 'Notificações',
            pageTitle: 'Gerenciar notificações',
            icon: 'message',
            addNewButtonText: 'Adicionar notificação',
            pageTitleAdd: 'Adicionar notificação',
            pageTitleEdit: 'Editar notificação',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar uma notificação',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar uma notificação',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              title: {
                key: 'title',
                placeholder: 'Digite um título...',
                label: 'Título',
              },
              notificationType: {
                key: 'notificationType',
                label: 'Tipo',
              },
            },
            details: {
              form: {
                title: {
                  label: 'Título',
                  errorOne: 'O campo "Título" é requerido. Verifique e tente novamente.',
                },
                body: {
                  label: 'Descrição',
                  errorOne: 'O campo "Descrição" é requerido. Verifique e tente novamente.',
                },
                notificationType: {
                  label: 'Tipo',
                  errorOne: 'O campo "Tipo" é requerido. Verifique e tente novamente.',
                },
                isSchedule: {
                  label: 'Envio agendado?',
                },
                sendForAllUsers: {
                  label: 'Enviar para todos os usuários',
                },
                scheduledDate: {
                  label: 'Data de agendamento',
                  sendNow: 'Envio imediato',
                  errorOne: 'O campo "Data de agendamento" é requerido. Verifique e tente novamente.',
                  errorTwo: 'A data de agendamento não pode ser no passado. Verifique e tente novamente.',
                },
                userIdList: {
                  errorOne: 'Selecione pelo menos um usuário. Verifique e tente novamente.',
                },
              },
              advancedFilter: {
                user: {
                  key: 'user',
                  name: {
                    key: 'name',
                    label: 'Nome',
                    placeholder: 'Digite o nome do usuário...',
                  },
                  email: {
                    key: 'email',
                    label: 'E-mail',
                    placeholder: 'Digite o e-mail do usuário...',
                  },
                },
              },
              messages: {
                successCreate: 'Notificação adicionada com sucesso.',
                successEdit: 'Notificação atualizada com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Título',
                },
                notificationType: {
                  key: 'notificationType',
                  title: 'Tipo',
                },
                scheduledDate: {
                  key: 'scheduledDate',
                  title: 'Agendamento',
                  sendNow: 'Envio imediato',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },
          email: {
            url: '/painel/gerencimanto/emails',
            sidebarTitle: 'Emails',
            pageTitle: 'Emails',
            icon: 'mail',
            pageTitleAdd: 'Criar e-mail',
            addNewButtonText: 'Adicionar email',
            pageDescriptionAdd: 'Preencha os campos abaixo para adicionar um email',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar um email',
            messages: {
              addSuccess: 'Adicionada com sucesso.',
              editSuccess: 'Editada com sucesso.',
              deleteSuccess: 'Removida com sucesso.',
            },
            advancedFilter: {
              panelTitle: 'Filtros avançados',
              title: {
                key: 'title',
                placeholder: 'Digite um título...',
                label: 'Título',
              },
              emailType: {
                key: 'emailType',
                label: 'Tipo',
              },
            },
            details: {
              form: {
                title: {
                  label: 'Título',
                  errorOne: 'O campo "Título" é requerido. Verifique e tente novamente.',
                },
                body: {
                  label: 'Descrição',
                  errorOne: 'O campo "Descrição" é requerido. Verifique e tente novamente.',
                },
                emailType: {
                  label: 'Tipo',
                  errorOne: 'O campo "Tipo" é requerido. Verifique e tente novamente.',
                },
                isSchedule: {
                  label: 'Envio agendado?',
                },
                sendForAllUsers: {
                  label: 'Enviar para todos os usuários',
                },
                scheduledDate: {
                  label: 'Data de agendamento',
                  sendNow: 'Envio imediato',
                  errorOne: 'O campo "Data de agendamento" é requerido. Verifique e tente novamente.',
                  errorTwo: 'A data de agendamento não pode ser no passado. Verifique e tente novamente.',
                },
                userIdList: {
                  errorOne: 'Selecione pelo menos um usuário. Verifique e tente novamente.',
                },
              },
              messages: {
                successCreate: 'Email adicionado com sucesso.',
                successEdit: 'Email atualizado com sucesso.',
              },
              errors: {
                generic: 'Algo deu errado. Tente novamente mais tarde.',
              },
            },
            dataTable: {
              columns: {
                title: {
                  key: 'title',
                  title: 'Título',
                },
                emailType: {
                  key: 'emailType',
                  title: 'Tipo',
                },
                scheduledDate: {
                  key: 'scheduledDate',
                  title: 'Agendamento',
                  sendNow: 'Envio imediato',
                },
                createdAt: {
                  key: 'createdAt',
                  title: 'Data de cadastro',
                },
                actions: {
                  key: 'id',
                  title: 'Ações',
                  goToDetailsText: 'Detalhes',
                  removeText: 'Remover',
                },
              },
            },
          },

          settings: {
            url: '/painel/gerenciamento/configuracoes',
            sidebarTitle: 'Configurações',
            pageTitle: 'Configurações',
            icon: 'tool',
            pageTitleEdit: 'Editar configurações',
            addNewButtonText: 'Adicionar email',
            pageDescriptionEdit: 'Preencha os campos abaixo para editar as configurações',
            messages: {
              successUpdate: 'Configurações atualizadas com sucesso.',
              syncDriverWeekSchedule: 'Horários de motoristas sincronizados com sucesso.',
            },
            errors: {
              syncDriverWeekScheduleFailed: 'Um erro ocorreu ao tentar sincronizar os horários de motoristas.',
            },
            form: {
              deliveryFastEnabled: {
                label: 'Abastecimento turbo',
              },
              fullTankPrice: {
                label: 'Preço máximo para tanque cheio',
              },
              fullTankLiters: {
                label: 'Litragem máxima para tanque cheio',
              },
              openingHour: {
                label: 'Hora de abertura',
              },
              openingMinute: {
                label: 'Minuto de abertura',
              },
              closingHour: {
                label: 'Hora de fechamento',
              },
              deliveryForecastMinutes: {
                label: 'Tempo previsto por entrega (em minutos)',
              },
              deliveryFee: {
                label: 'Taxa de entrega',
              },
              serviceStationFeePercentage: {
                label: 'Porcentagem do posto de combustível',
              },
              syncDriverWeekScheduleButton: {
                label: 'Sincronizar horários de motoristas da semana',
              },
            },
          },
        },
      },
    },
  },
  advancedAutoComplete: {
    driver: {
      inputLabel: 'Motorista (apenas para pedidos pagos)',
      modal: {
        title: 'Selecionar motorista',
        dataTable: {
          name: {
            label: 'Nome',
          },
          email: {
            label: 'E-mail',
          },
        },
      },
    },
  },
  enum: {
    profileType: {
      admin: 'Admin',
      user: 'Usuário',
      driver: 'Motorista',
    },
    vehicleType: {
      customer: 'Cliente',
      versa: 'Versa',
    },
    fuelSupplyType: {
      vehicle: 'Próprio',
      compartment: 'Cliente',
    },
    vehicleServiceType: {
      fuelSupply: 'Abastecimento',
      automotiveServices: 'Serviços automotivos',
      all: 'Todos',
    },
    deliveryType: {
      fast: 'Turbo',
      scheduled: 'Agendado',
    },
    orderStatusType: {
      pendingPayment: 'Pagamento pendente',
      paied: 'Pago',
      inTransit: 'Em trânsito',
      fueling: 'Verso no local',
      provided: 'Abastecido',
      canceled: 'Cancelado',
    },
    addressType: {
      house: 'Casa',
      work: 'Trabalho',
      outro: 'Outro',
    },
    notificationType: {
      customer: 'Clientes',
      driver: 'Motoristas',
      all: 'Ambos',
    },
    emailType: {
      customer: 'Clientes',
      driver: 'Motoristas',
      all: 'Ambos',
    },
    problemType: {
      damage: 'Dano',
      value: 'Valor',
      delay: 'Demora na entrega',
      supply: 'Fornecedor',
      others: 'Outros',
    },
    reasonsTypes: {
      offSite: 'Não estarei no local',
      technicalProblems: 'Problemas técnicos',
      inabilityPark: 'Impossibilidade de estacionar próximo ao cliente para abastecer',
      supplyRestriction: 'Local com restrição de abastecimento',
      closedTank: 'Tampa do tanque fechada e tentativa de contato com o cliente sem sucesso',
      other: 'Outro',
    },
  },
};
