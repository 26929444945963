
const initialState = {
  selectives: null,
};

export default function driverSchedule(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
