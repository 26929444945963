import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import EmailRequests from '../../api/email';
export const ACTION_SAVE_EMAIL_PAGINATED = 'ACTION_SAVE_EMAIL_PAGINATED';

export const cleanPaginated = () => ({
  type: ACTION_SAVE_EMAIL_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
    };

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        title: parameters.searchInput,
      };
    }

    dispatch({
      type: ACTION_SAVE_EMAIL_PAGINATED,
      payload: null,
    });

    const response = await EmailRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_EMAIL_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await EmailRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
