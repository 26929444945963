import React from 'react';
import {
  Checkbox,
} from 'antd';

const AdvancedCheckbox = ({
  label,
  onChange,
  value,
  disabled,
}) => (
  <div className="advanced-checkbox">
    <label className="advanced-checkbox__label">
      <Checkbox
        onChange={(e) => onChange(e.target.checked)}
        defaultChecked={!!value}
        disabled={disabled || false}
        checked={!!value}
      >
        <span className="advanced-checkbox__label__inner">{label}</span>
      </Checkbox>
    </label>
  </div>
);

export default AdvancedCheckbox;
