import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import AdvancedInput from '../../../components/shared/AdvancedInput';
import { AdministratorSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { ViewType } from '../../enum/viewType';
import { validateEmail } from '../../utils/string';
import AdvancedMaskedInput from '../../../components/shared/AdvancedMaskedInput';

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordVisible: false,
      form: {
        name: null,
        email: null,
        cellphone: null,
        password: null,
        passwordConfirmation: null,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        changePasswordVisible: false,
        form: {
          name: details && details.name,
          email: details && details.email,
          cellphone: details && details.cellphone ? details.cellphone.replace(I18n.t('application.phoneCountryCode'), '') : null,
          password: null,
          passwordConfirmation: null,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;
    const { viewType } = this.props;

    if (!validateEmail(form.email)) {
      return message.error(I18n.t('routes.panel.administration.items.user.details.errors.email'));
    }

    const schema = yup.object().shape({
      name: yup.string().required(),
      email: yup.string().required(),
      cellphone: yup.string().required(),
    });

    schema
      .isValid(form)
      .then((valid) => {
        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else {
          if (viewType === ViewType.ADD || this.state.changePasswordVisible) {
            if (!(form.password && form.passwordConfirmation)) {
              return message.error(I18n.t('routes.panel.administration.items.user.details.errors.passwordsRequired'));
            }
            if (form.password !== form.passwordConfirmation) {
              return message.error(I18n.t('routes.panel.administration.items.user.details.errors.passwordsMismatch'));
            }
          }

          const { details, submitFunction } = this.props;

          if (viewType === ViewType.EDIT && !this.state.changePasswordVisible) {
            delete form.password;
            delete form.passwordConfirmation;
          }

          if (details) {
            submitFunction(details.id, form);
          } else {
            submitFunction(null, form);
          }
        }
      }).catch(() => I18n.t('shared.genericError'));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading,
      viewType,
    } = this.props;
    const { form, changePasswordVisible } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.user.details.form.email.label')}
                    value={form && form.email}
                    onChange={(val) => this.fieldChange('email', val)}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>

                <Col span={6}>
                  <AdvancedMaskedInput
                    label={I18n.t('routes.panel.administration.items.user.details.form.cellphone.label')}
                    value={form && form.cellphone}
                    onChange={(val) => this.fieldChange('cellphone', val)}
                    mask={I18n.t('shared.phoneMask')}
                    disabled={viewType === ViewType.EDIT}
                  />
                </Col>

                <Col span={10}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.user.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', val)}
                  />
                </Col>
              </Row>

              {viewType === ViewType.EDIT && (
                <Row
                  gutter={16}
                  className="form-password-divider"
                >
                  <Col span={24}>
                    <Checkbox
                      onChange={(e) => this.setState({ changePasswordVisible: e.target.checked })}
                    >
                      {I18n.t('routes.panel.administration.items.user.details.changePasswordPhrase')}
                    </Checkbox>
                  </Col>
                </Row>
              )}

              {(viewType === ViewType.ADD || changePasswordVisible) && (
                <Row gutter={16}>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.administration.items.user.details.form.password.label')}
                      value={form && form.password}
                      onChange={(val) => this.fieldChange('password', val)}
                      isPassword
                    />
                  </Col>
                  <Col span={12}>
                    <AdvancedInput
                      label={I18n.t('routes.panel.administration.items.user.details.form.passwordConfirmation.label')}
                      value={form && form.passwordConfirmation}
                      onChange={(val) => this.fieldChange('passwordConfirmation', val)}
                      isPassword
                    />
                  </Col>
                </Row>
              )}

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.administration.items.user.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: AdministratorSelectors.getDetails(state),
});

export default connect(
  mapStateToProps,
  null,
)(UserForm);
