import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { SettingsActions } from '../../../app/redux/actions';
import { LoadingSelectors, SettingsSelectors } from '../../../app/redux/reducers';
import { ViewType } from '../../../app/enum/viewType';
import SettingsForm from '../../../app/forms/settings/SettingsForm';

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.ADD,
    };
  }

  componentDidMount() {
    const { getSelectives } = this.props;
    getSelectives();
  }

  save(data) {
    const { update } = this.props;

    try {
      update(data, () => {
        message.success(I18n.t('routes.panel.manage.items.settings.messages.successUpdate'));
      });
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.manage.items.settings.url')}>
                <Icon type={I18n.t('routes.panel.manage.items.settings.icon')} /> <span>{I18n.t('routes.panel.manage.items.settings.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {I18n.t('routes.panel.manage.items.settings.pageTitleEdit')}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.manage.items.settings.icon')} />
                  </span>
                  {I18n.t('routes.panel.manage.items.settings.pageTitleEdit')}
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {I18n.t('routes.panel.manage.items.settings.pageDescriptionEdit')}
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <SettingsForm
                      id={id}
                      submitFunction={(data) => this.save(data)}
                      viewType={viewType}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  selectives: SettingsSelectors.getSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSelectives: () => dispatch(SettingsActions.getSelectives()),
  update: (data, callback) => dispatch(SettingsActions.update(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);
