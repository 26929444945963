import {
  ACTION_SAVE_BRAND_SELECTIVES,
} from '../actions/brand';

const initialState = {
  selectives: null,
};

export default function brand(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_BRAND_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getSelectives(state) {
  return state.brand.selectives;
}
