import { Translate } from 'react-redux-i18n';
import React from 'react';

export const EmailType = {
  CUSTOMER: 1,
  DRIVER: 2,
  ALL: 3,
};

export const EmailTypeArray = [
  { id: EmailType.CUSTOMER, name: <Translate value="enum.emailType.customer" /> },
  { id: EmailType.DRIVER, name: <Translate value="enum.emailType.driver" /> },
  { id: EmailType.ALL, name: <Translate value="enum.emailType.all" /> },
];

export const GetEmailTypeTitle = (value) => (EmailTypeArray.find((o) => o.id === value).name);
