import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
  Divider,
  Checkbox,
} from 'antd';
import * as yup from 'yup';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined } from '@ant-design/icons';

import AdvancedInput from '../../../components/shared/AdvancedInput';
import { FuelSelectors, AuthSelectors, LoadingSelectors } from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import { firstLetterUpperCase } from '../../utils/firstLetterUpperCase';

class FuelForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: null,
        price: null,
        isAvailable: true,
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const {
      details,
      id,
    } = this.props;

    if (details && id !== 'add') {
      this.setState({
        form: {
          name: details && details.name,
          price: details && parseFloat(details.price),
          isAvailable: details && details.isAvailable,
        },
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const { form } = this.state;

    const schema = yup.object().shape({
      name: yup.string().required(),
      isAvailable: yup.bool().required(),
    });

    schema
      .isValid(form)
      .then((valid) => {

        if (!valid) {
          message.error(I18n.t('shared.genericFieldsError'));
        } else {
          const { details, submitFunction } = this.props;

          if (details) {
            submitFunction(details.id, form);
          } else {
            submitFunction(null, form);
          }
        }
      }).catch(() => I18n.t('shared.genericError'));
  }

  fieldChange(name, value) {
    const { form } = this.state;
    form[name] = value;
    this.setState({ form });
  }

  render() {
    const {
      loading,
    } = this.props;
    const { form } = this.state;
    return (
      <Row>
        {loading === 0 && (
          <Col>
            <form
              name="form"
              onSubmit={(ev) => this.onSubmit(ev)}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.fuel.details.form.name.label')}
                    value={form && form.name}
                    onChange={(val) => this.fieldChange('name', firstLetterUpperCase(val))}
                  />
                </Col>
                <Col span={12}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.fuel.details.form.price.label')}
                    value={form && form.price}
                    onChange={(val) => this.fieldChange('price', val)}
                    isMoney
                    moneyPrecision={2}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Checkbox
                    checked={form && form.isAvailable}
                    onChange={(ev) => this.fieldChange('isAvailable', ev.target.checked)}
                  >
                    {I18n.t('routes.panel.administration.items.fuel.details.form.isAvailable.label')}
                  </Checkbox>
                </Col>
              </Row>

              <Divider />

              <Row>
                <Col
                  span={24}
                  className="text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.backButtonText')}
                    href={I18n.t('routes.panel.administration.items.fuel.url')}
                  />

                  <Divider
                    className="form-button-divider"
                    type="vertical"
                  />

                  <AdvancedButton
                    htmlType="submit"
                    text={I18n.t('shared.saveButtonText')}
                    icon={<SaveOutlined />}
                  />
                </Col>
              </Row>
            </form>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  details: FuelSelectors.getDetails(state),
});

export default connect(
  mapStateToProps,
  null,
)(FuelForm);
