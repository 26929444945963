import React from 'react';
import moment from 'moment-timezone';
import { Divider, Modal } from 'antd';
import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

import AdvancedButton from '../AdvancedButton';
import AdvancedInput from '../AdvancedInput';
import AdvancedDatePicker from '../AdvancedDatePicker';
import { FieldType } from '../../../app/enum/fieldType';
import SearchDataTable from '../SearchDataTable';
import { LoadingSearchSelectors } from '../../../app/redux/reducers';
import AdvancedSelectAutoComplete from '../AdvancedSelectAutoComplete';

class AdvancedAutoCompleteInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      activeFilters: {},
      options: null,
      optionsSelectable: null,
      selectedRows: null,
      selectedValue: null,
      sortedInfo: {
        order: 'descend',
        columnKey: 'createdAt',
      },
    };
    this.dataTableRefSearch = React.createRef();
  }

  async componentDidMount() {
    await this.handleSearchSelectable();
  }

  onSelect(selectedValue) {
    const { onSelect } = this.props;

    const { modalVisible, optionsSelectable } = this.state;
    if (modalVisible) {
      this.toggleModalVisibility();
    }

    const optionsSelectableUpdated = optionsSelectable;
    optionsSelectableUpdated.push(selectedValue);

    this.setState({
      selectedValue: selectedValue.id,
      optionsSelectable: optionsSelectableUpdated,
    });

    onSelect(selectedValue);
  }

  hasFooter() {
    const { multiple } = this.props;

    if (!multiple) {
      return {
        footer: null,
      };
    }
  }

  async onChangeAdvancedFilter(name, value) {
    const { activeFilters } = this.state;

    if (value) {
      activeFilters[name] = value;
    } else {
      delete activeFilters[name];
    }

    this.setState({ activeFilters });
  }

  async handleSearch(e) {
    e.preventDefault();
    const { searchMethod } = this.props;
    const { activeFilters } = this.state;
    const options = await searchMethod(activeFilters);
    this.setState({ options });
  }

  onSelectRow(selectedRows) {
    this.setState({ selectedRows });
  }

  async toggleModalVisibility() {
    const { modalVisible } = this.state;

    this.setState({
      modalVisible: !modalVisible,
    }, async () => {
      if (this.state.modalVisible) {
        const { searchMethod } = this.props;
        const { activeFilters } = this.state;

        const options = await searchMethod(activeFilters);
        this.setState({ options });
      }
    });
  }

  renderAdvancedFilterInput(key, filters) {
    const { activeFilters } = this.state;

    switch (filters[key].type) {
      case FieldType.DATE_PICKER:
        return (
          <AdvancedDatePicker
            onChange={(value) => this.onChangeAdvancedFilter(key, value ? moment(value).format() : null)}
            value={activeFilters[key] ? moment(activeFilters[key]) : null}
            label={filters[key].label}
          />
        );

      case FieldType.TEXT:
        return (
          <AdvancedInput
            onChange={(value) => this.onChangeAdvancedFilter(key, value)}
            value={activeFilters[key]}
            label={filters[key].label}
          />
        );

      default:
        return (
          <AdvancedInput
            onChange={(value) => this.onChangeAdvancedFilter(key, value)}
            value={activeFilters[key]}
            label={filters[key].label}
          />
        );
    }
  }

  parseRows(rows, data) {
    const result = rows.map((row) => ({
      key: row,
      title: data.modal.dataTable[row].label,
    }));

    result.push({
      key: Math.random().toString(36),
      render: (value, row) => (
        <div className="action-column">
          <AdvancedButton
            type="link"
            icon={<ArrowRightOutlined />}
            onClick={() => this.onSelect(row)}
          />
        </div>
      ),
    });

    return result;
  }

  async handleSearchSelectable(value) {
    const { searchMethod } = this.props;
    const optionsSelectable = await searchMethod({ name: value });
    if (optionsSelectable && optionsSelectable.rows && optionsSelectable.rows.length > 0) {
      this.setState({ optionsSelectable: optionsSelectable.rows });
    }
  }

  onSelectSelectable(selectedValue) {
    const { onSelect } = this.props;
    this.setState({ selectedValue });
    onSelect({ id: selectedValue });
  }

  render() {
    const {
      placeholder,
      value,
      disabled,
      data,
      searchMethod,
      loadingSearch,
      multiple,
      hideSearch,
    } = this.props;

    const {
      modalVisible,
      optionsSelectable,
      activeFilters,
      selectedValue,
    } = this.state;

    const advancedSearch = data.modal.advancedSearch && Object.keys(data.modal.advancedSearch);
    const dataTableRows = Object.keys(data.modal.dataTable);

    return (
      <div className="advanced-auto-complete-input">

        <Modal
          className="advanced-auto-complete-input__modal"
          title={data.modal.title}
          visible={modalVisible}
          onOk={(val) => this.onSelect(val)}
          onCancel={() => this.toggleModalVisibility()}
          cancelText={I18n.t('shared.cancel')}
          okText={I18n.t('shared.useValues')}
          width="60vw"
          {...this.hasFooter()}
        >
          <form onSubmit={(e) => this.handleSearch(e)}>
            {!hideSearch && (
              <div className="advanced-auto-complete-input__modal__search">
                <Search
                  onChange={(ev) => {
                    ev.preventDefault();
                    this.onChangeAdvancedFilter('searchInput', ev.target.value);
                  }}
                  placeholder={I18n.t('shared.searchSomething')}
                  autoFocus
                />
              </div>
            )}
            {advancedSearch && (
              <div className="advanced-auto-complete-input__modal__advanced-filters">
                <div className="row">
                  {advancedSearch && advancedSearch.map((key, index) => (
                    <div
                      className="col-4"
                      key={index.toString()}
                    >
                      {this.renderAdvancedFilterInput(key, data.modal.advancedSearch)}
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-12 text-right">
                    <AdvancedButton
                      text={I18n.t('shared.search')}
                      icon={<SearchOutlined />}
                      htmlType="submit"
                    />
                    <Divider />
                  </div>
                </div>
              </div>
            )}
          </form>

          <div className="advanced-auto-complete-input__modal__list">
            <SearchDataTable
              getMethod={(parameters) => searchMethod(parameters)}
              activeFilters={activeFilters}
              ref={this.dataTableRefSearch}
              loading={loadingSearch > 0}
              onSelect={(id) => this.onSelect(id)}
              onSelectRow={(val) => this.onSelectRow(val)}
              columns={this.parseRows(dataTableRows, data)}
              multiple={multiple || false}
            />
          </div>
        </Modal>

        <label className="advanced-auto-complete-input__label">
          <AdvancedSelectAutoComplete
            options={optionsSelectable}
            onChange={(ev) => this.onSelectSelectable(ev)}
            onSearch={(val) => this.handleSearchSelectable(val)}
            label={data.inputLabel}
            value={selectedValue || (optionsSelectable && optionsSelectable.length > 0 ? value : null)}
            disabled={disabled}
            placeholder={placeholder}
            hasValue
          />
          <span className="advanced-auto-complete-input__label__modal-toggle">
            <AdvancedButton
              className="advanced-auto-complete-input__label__modal-toggle__button"
              onClick={() => this.toggleModalVisibility()}
              icon={<SearchOutlined />}
              disabled={disabled}
            />
          </span>
        </label>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingSearch: LoadingSearchSelectors.getLoadingSearch(state),
});

export default connect(
  mapStateToProps,
  null,
)(AdvancedAutoCompleteInput);
