import {
  ACTION_SAVE_EMAIL_PAGINATED,
} from '../actions/email';

const initialState = {
  paginatedData: null,
  details: null,
};

export default function email(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_EMAIL_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.email.paginatedData;
}
