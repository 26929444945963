export function getInitials(name) {
  let initials = name.match(/\b\w/g) || [];
  initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  if (initials && initials.length > 0 && initials.length > 2) {
    initials = initials.substr(0, 2);
  }
  return initials;
}

export function validateEmail(str) {
  // eslint-disable-next-line max-len,no-useless-escape
  const exp =
    /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return exp.test(str);
}

export function removeSpecialChars(str) {
  return str ? str.replace(/[^A-Za-z0-9]/g, '').replace(/\/s/g, '') : null;
}

export function maskCellphone(str) {
  if (str) {
    str = removeSpecialChars(str);
    str = str.replace('55', '').match(/(\d{2})(\d{1})(\d{4})(\d{4})/);
    str = `(${str[1]}) ${str[2]} ${str[3]}-${str[4]}`;
  }

  return str;
}

export function maskMoney(value) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function maskDocument(str) {
  if (str) {
    str = removeSpecialChars(str);

    if (str.length < 14) {
      str = str.replace(/(\d{3})(\d)/,'$1.$2');
      str = str.replace(/(\d{3})(\d)/,'$1.$2');
      str = str.replace(/(\d{3})(\d{1,2})$/,'$1-$2');

    } else {
      str = str.replace(/^(\d{2})(\d)/,'$1.$2');
      str = str.replace(/^(\d{2})\.(\d{3})(\d)/,'$1.$2.$3');
      str = str.replace(/\.(\d{3})(\d)/,'.$1/$2');
      str = str.replace(/(\d{4})(\d)/,'$1-$2');
    }
  }

  return str;
}