import { message } from 'antd';
import { I18n } from 'react-redux-i18n';

import {
  addLoading,
  removeLoading,
} from './loading';
import DriverScheduleRequests from '../../api/driver-schedule';

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const syncWeek = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let response = null;
    response = await DriverScheduleRequests.syncWeek();

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};
