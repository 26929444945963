import React from 'react';
import {
  DownOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Icon,
  Row,
  Col,
  Avatar,
  Dropdown,
  notification,
  Button, Spin,
} from 'antd';
import { connect } from 'react-redux';
import {
  I18n,
} from 'react-redux-i18n';
import { Helmet } from 'react-helmet';
import { Link } from '@reach/router';

import { getInitials } from '../utils/string';
import * as AuthSelectors from '../redux/reducers/auth';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../redux/actions';
import { LoadingSelectors } from '../redux/reducers';

const { SubMenu } = Menu;

class Panel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      dropdownVisible: false,
    };
    if (!this.props.me) {
      this.props.getMe();
    }

    this.menuItems = Object.values(I18n.t('routes.panel')).filter((o) => o.sidebarVisible === 'y');
  }

  getCurrent() {
    return window.location.pathname;
  }

  getCurrentSubMenu() {
    const path = window.location.pathname;
    const pathSplitted = path.split('/');

    let theIndex = null;

    if (pathSplitted.length >= 4) {
      this.menuItems.forEach((o, i) => {
        if (o.sidebarTitle.toLowerCase() === pathSplitted[2].toLowerCase()) {
          theIndex = i.toString();
        }
      });
    }

    return theIndex;
  }

  toggle = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed },
    );
  };

  openLogoutNotification() {
    try {
      const { logout } = this.props;
      const key = `open${Date.now()}`;
      const btn = (
        <Button
          type="primary"
          size="small"
          onClick={() => {
            logout();
            notification.close(key);
          }}
        >
          {I18n.t('shared.logout.confirmButton')}
        </Button>
      );
      notification.open({
        message: I18n.t('shared.logout.confirmation'),
        btn,
        key,
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const {
      title,
      container,
      id,
      me,
      loading,
    } = this.props;
    const { dropdownVisible } = this.state;
    const { Header, Sider } = Layout;

    return (
      <Layout className="panel__layout">
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <Spin
          className="panel__layout__loading"
          spinning={loading > 0}
          tip={I18n.t('shared.loading')}
        />

        <Sider
          trigger={null}
          collapsible
          collapsed={this.state.collapsed}
          width="260"
        >
          <div className="logo" />

          <Menu
            theme="dark"
            mode="inline"
            className="panel__layout__menu"
            selectedKeys={[this.getCurrent()]}
            defaultOpenKeys={[this.getCurrentSubMenu()]}
          >
            <div className="panel__layout__menu__img__wrapper">
              <img
                alt="sidebar logo"
                className="panel__layout__menu__img"
                src="/assets/img/ic_icon_white.png"
              />
            </div>
            <div className="panel__layout__userinfo">
              <div className="panel__layout__userinfo__name">
                <p>{me && me.name}</p>
              </div>
              <div className="panel__layout__userinfo__company">
                <p>{me && me.company ? me.company.name : I18n.t('enum.profileType.admin')}</p>
              </div>
            </div>

            {me && this.menuItems.map((item, itemIndex) => (
              <SubMenu
                key={itemIndex.toString()}
                className="panel__layout__menu__submenu"
                title={(
                  <span className="panel__layout__menu__submenu__inner">
                    <Icon
                      type={item.icon}
                      theme="outlined"
                    />
                    <span className="panel__layout__menu__submenu__inner__title">{item.sidebarTitle}</span>
                  </span>
                )}
              >
                {Object.values(item.items).map((subItem) => (
                  <Menu.Item key={subItem.url}>
                    <Link to={subItem.url}>
                      <Icon
                        type={subItem.icon}
                        theme="outlined"
                      />
                      <span>
                        {subItem.sidebarTitle}
                      </span>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            ))}
          </Menu>
        </Sider>
        <Layout className="panel__layout__wrapper">
          <Header className="panel__layout__header">
            <Row>
              <Col span={18}>
                <Icon
                  className="panel__trigger_button"
                  type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
              </Col>
              <Col span={6}>
                <div className="panel__layout__header__avatar">
                  <Dropdown
                    onVisibleChange={(ev) => this.setState({ dropdownVisible: ev })}
                    overlay={(
                      <Menu>
                        <Menu.Item>
                          <div className="panel__layout__header__avatar__userdata">
                            <strong>
                              {me && me.name}<br />
                            </strong>
                            <small>{me && me.email}</small>
                            {me && me.company && (
                              <span className="panel__layout__header__avatar__userdata__company">
                                {me.company.name}
                              </span>
                            )}
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <AdvancedButton
                            onClick={() => this.openLogoutNotification()}
                            text={I18n.t('shared.logout.menuText')}
                            type="link"
                          />
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Avatar
                      className="panel__layout__header__avatar__item"
                      size={32}
                    >
                      {me && getInitials(me.name)}
                    </Avatar>
                  </Dropdown>
                  <DownOutlined
                    className="panel__layout__header__avatar__down"
                    rotate={dropdownVisible ? 180 : 0}
                  />
                </div>
              </Col>
            </Row>
          </Header>

          {me && (id ? React.cloneElement(container, { id }) : container)}

          <div className="panel__layout__content__footer">
            {I18n.t('application.footer.poweredBy')}&nbsp;
            <Link
              to={I18n.t('application.footer.poweredByBrandUrl')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {I18n.t('application.footer.poweredByBrand')}
            </Link> - {I18n.t('application.version.title')}: {I18n.t('application.version.number')}
          </div>
        </Layout>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: AuthSelectors.isAuthenticated(state),
  me: AuthSelectors.getMe(state),
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: (user) => dispatch(AuthActions.logout(user)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Panel);
