/* eslint-disable react/sort-comp */
import React from 'react';
import {
  Divider,
  Layout,
  Row,
  Col,
  message,
  Breadcrumb,
  Icon,
  Collapse,
  Tag,
  Button,
  Tooltip,
  Modal,
} from 'antd';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { ArrowRightOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import {
  OrderActions, AuthActions, FuelActions, DriverActions,
} from '../../../../app/redux/actions';
import {
  OrderSelectors,
  AuthSelectors,
  LoadingSelectors,
  FuelSelectors,
  DriverSelectors,
} from '../../../../app/redux/reducers';

import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import { GetDeliveryTypeTitle, DeliveryTypeArray } from '../../../../app/enum/deliveryType';
import OrderStatus, { OrderStatusTypeArray, GetOrderStatus } from '../../../../app/enum/orderStatusType';
import QueryStringUtils from '../../../../app/utils/queryString';
import AdvancedInput from '../../../../components/shared/AdvancedInput';
import AdvancedSelect from '../../../../components/shared/AdvancedSelect/AdvancedSelect';
import AdvancedDatePicker from '../../../../components/shared/AdvancedDatePicker';
import { maskMoney } from '../../../../app/utils/string';
import { Reasons, ReasonsTypeArray } from '../../../../app/enum/reasonsType';
import { formatCurrency } from '../../../../app/utils/currency';
import { serializeOrderBy } from '../../../../app/utils/api';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  orderNumber: null,
  fuelId: null,
  orderStatus: null,
  city: null,
  neighborhood: null,
  street: null,
  zipCode: null,
  createdAtStart: null,
  createdAtEnd: null,
  isModalVisible: false,
  reason: null,
  description: null,
  idOrder: null,
  ordering: null,
  orderBy: 'createdAt',
  schedulingOrder: null,
  literOrder: null,
  isDESC: true,
  minValue: null,
  maxValue: null,
};

class OrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  componentDidMount() {
    const { params } = qs.get();
    const { getFuelSelectives, getDriversSelectives, getAbsoluteValues, getStatesSelectives } = this.props;

    if (params) {
      this.setState({ params });
    }

    getStatesSelectives();
    getFuelSelectives();
    getDriversSelectives();
    getAbsoluteValues();
  }

  componentWillUnmount(){
    this.cleanAdvancedFilters();
  }

  getPaginatedData(params) {
    const { params: oldParams } = this.state;
    const { getPaginated } = this.props;

    const parameters = {
      ...oldParams,
      ...params
    };

    this.setState({ params: parameters }, () => {
      getPaginated({
        ...parameters,
        orderBy: serializeOrderBy(parameters.orderBy),
      });
    });
  }

  getPaginatedDataAndRefreshTable(params) {
    params = this.formatSearchParameters(params, true);

    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? (value instanceof moment ? value.format() : value.toString()) : null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.administration.items.order.messages.deleteSuccess'));
      this.dataTableRef.current.reset();
      this.applyAdvancedFilters();
    });
  }

  cancel(data) {
    this.props.cancel(data, () => {
      message.success(I18n.t('routes.panel.administration.items.order.messages.cancelSuccess'));
      this.dataTableRef.current.reset();
      this.getPaginatedData();
      this.handleClearModalAfterCloseOrCancel();
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
      params = {
        ...params,
        page: 1,
        offset: 0,
      };
    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  exportCsv() {
    const { exportCsv } = this.props;
    const { params } = this.state;

    try {
      exportCsv(params, () => {
        message.success(I18n.t('routes.panel.administration.items.driverRating.messages.exportCsvSuccess'));
      });
    } catch (e) {
      message.error(I18n.t('routes.panel.administration.items.orders.errors.exportCsv'));
    }
  }

  handleShowModal = (id) => {
    const { params } = this.state;
    params.isModalVisible = true;
    params.idOrder = id;
    this.setState({ params });
  }

  handleOkModal = () => {
    const { params } = this.state;
    params.isModalVisible = false;
    this.setState({ params });
  }

  handleClearModalAfterCloseOrCancel = () => {
    const { params } = this.state;
    params.isModalVisible = false;
    this.setState({ params });
    qs.set({ params: undefined });
    window.location.reload();
  }

  formatSearchParameters(searchParameters, updateState = false) {
    if (searchParameters && searchParameters.minValue) {
      searchParameters.minValue = formatCurrency(searchParameters.minValue);
    }

    if (searchParameters && searchParameters.maxValue) {
      searchParameters.maxValue = formatCurrency(searchParameters.maxValue);
    }

    if (updateState) {
      this.setState({ params: searchParameters });
    }

    return searchParameters;
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      paginatedData,
      loading,
      fuelSelectives,
      driverSelectives,
      absoluteValues,
      statesSelectives,
      updateStatus,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" />
              <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.order.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--general-values">
          <Content className="panel__layout__content panel__layout__content--general-value">
            <div className="panel__general-value">
              <span className="panel__general-value__icon">
                <Icon type={I18n.t('routes.panel.administration.items.order.icon')} />
              </span>
              <div>
                <span className="panel__general-value__number">
                  {absoluteValues && absoluteValues.orderCount}
                </span>
                <span className="panel__general-value__text">Total de pedidos</span>
              </div>
            </div>
          </Content>
          <Content className="panel__layout__content panel__layout__content--general-value">
            <div className="panel__general-value">
              <span className="panel__general-value__icon">
                <Icon type="bar-chart" />
              </span>
              <div>
                <span className="panel__general-value__number">
                  R$ {absoluteValues && absoluteValues.averageOrderPrice.replace('.', ',')}
                </span>
                <span className="panel__general-value__text">Valor médio do pedido</span>
              </div>
            </div>
          </Content>
          <Content className="panel__layout__content panel__layout__content--general-value">
            <div className="panel__general-value">
              <span className="panel__general-value__icon">
                <Icon type="stock" />
              </span>
              <div>
                <span className="panel__general-value__number">
                  R$ {absoluteValues && absoluteValues.totalRevenue.replace('.', ',')}
                </span>
                <span className="panel__general-value__text">Faturamento total</span>
              </div>
            </div>
          </Content>
          <Content className="panel__layout__content panel__layout__content--general-value">
            <div className="panel__general-value">
              <span className="panel__general-value__icon">
                <Icon type="user" />
              </span>
              <div>
                <span className="panel__general-value__number">
                  {absoluteValues && absoluteValues.totalCustomers}
                </span>
                <span className="panel__general-value__text">Total de clientes</span>
              </div>
            </div>
          </Content>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.administration.items.order.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.orderNumber.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.orderNumber.key'), val)}
                    value={params && params.orderNumber}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.fuelId.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.fuelId.key'), val)}
                    options={fuelSelectives}
                    value={params && params.fuelId}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.orderStatus.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.orderStatus.key'), val)}
                    value={params && params.orderStatus ? parseInt(params.orderStatus, 10) : null}
                    options={OrderStatusTypeArray}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.driverId.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.driverId.key'), val)}
                    options={driverSelectives}
                    value={params && params.driverId}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.state.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.state.key'), val)}
                    options={statesSelectives && statesSelectives.map((stateSelective) => ({ id: stateSelective.state, name: stateSelective.state }))}
                    value={params && params.state}
                    placeholder={'SP'}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.city.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.city.key'), val)}
                    value={params && params.city}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.neighborhood.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.neighborhood.key'), val)}
                    value={params && params.neighborhood}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.street.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.street.key'), val)}
                    value={params && params.street}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.zipCode.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.zipCode.key'), val)}
                    value={params && params.zipCode}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.createdAtStart.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.createdAtStart.key'), val)}
                    value={params && params.createdAtStart && moment(params.createdAtStart)}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.createdAtEnd.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.createdAtEnd.key'), val)}
                    value={params && params.createdAtEnd && moment(params.createdAtEnd)}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Col>
                <Col span={8}>
                  <AdvancedSelect
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.deliveryType.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.deliveryType.key'), val)}
                    value={params && params.deliveryType ? parseInt(params.deliveryType, 10) : null}
                    options={DeliveryTypeArray}
                  />
                </Col>
              </Row>

              <Row className='mb-3 mt-2'>
                <strong>{I18n.t('routes.panel.administration.items.order.advancedFilter.insertValue')}</strong>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.minValue.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.minValue.key'), val)}
                    value={params && params.minValue}
                    isMoney
                    moneyPrecision={2}
                  />
                </Col>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.order.advancedFilter.maxValue.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.advancedFilter.maxValue.key'), val)}
                    value={params && params.maxValue}
                    isMoney
                    moneyPrecision={2}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>
        <Content className="panel__layout__content">
          <div className="orders">
            <Row className="d-flex" gutter={24}>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.order.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.order.pageTitle')}
                </h2>
              </Col>


              <Col className="text-right ml-8 d-flex justify-content-end" span={12}>
                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.order.exportCsvButtonText')}
                  onClick={() => this.exportCsv()}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.order.icon')} />}
                />

                <Divider
                  className="mt-2"
                  type="vertical"
                />

                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.order.updateStatus')}
                  onClick={() => updateStatus({}, () => {
                    this.getPaginatedData()
                  })}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.order.icon')} />}
                />

              </Col>
            </Row>
            <Col className="mt-1 text-right" gutter={2}>
              <strong>
                { paginatedData && paginatedData.rows &&
                  (
                    I18n.t('routes.panel.administration.items.order.lastUpdate') +
                    DateUtils.humanizeDateTime(paginatedData.rows[0].updatedAt, 'DD/MM/YYYY HH:mm')
                  )
                }
              </strong>
            </Col>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.orderNumber.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.orderNumber.title'),
                      render: (value, row) => (
                        <div className="no-break-column">
                          #{value}
                          {row.orderProblems && row.orderProblems.length ? (
                            <div className="alert-pointer" />
                          ) : null}
                        </div>
                      ),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.street.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.location.title'),
                      render: (value, row) => `${row.street}, ${row.number} - ${row.neighborhood} - ${row.city} / ${row.state}`,
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.invoiceDocument.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.invoiceDocument.title'),
                      render: (value) => (value ? I18n.t('shared.yes') : I18n.t('shared.no')),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.requestedLiters.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.requestedLiters.title'),
                      render: (value) => parseFloat(value).toFixed(3) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.fuel.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.fuel.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.refundAmount.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.refundAmount.title'),
                      render: (value, data) => maskMoney(data && data.orderPayment && data.orderPayment.refundAmount) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.totalAmount.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.totalAmount.title'),
                      render: (value, data) => maskMoney(data && data.orderPayment && data.orderPayment.totalAmount) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.deliveryType.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.deliveryType.title'),
                      render: (value) => GetDeliveryTypeTitle(value) || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.orderStatus.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.orderStatus.title'),
                      render: (value) => <Tag color={GetOrderStatus(value).color}>{GetOrderStatus(value).name}</Tag> || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.driverSchedule.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.driverSchedule.title'),
                      render: (value, row) => (
                        row.driverSchedule
                        && row.driverSchedule.startAt
                        && row.driverSchedule.endAt
                        && `${moment(row.driverSchedule.startAt).format('DD/MM/YYYY')} ${moment(row.driverSchedule.startAt).format('HH:mm')} - ${moment(row.driverSchedule.endAt).format('HH:mm')}`)
                        || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.order.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.order.dataTable.columns.actions.key'),
                      title: '',
                      render: (id, row) => (
                        <div className="dataTable__item--right">
                          <Button
                            type="link"
                            disabled={row && [OrderStatus.CANCELED, OrderStatus.PROVIDED].includes(row.orderStatus)}
                            onClick={() => this.handleShowModal(id)}
                          >
                            <CloseCircleOutlined />
                          </Button>
                          <Modal
                            title={I18n.t('routes.panel.administration.items.order.modal.title')}
                            visible={params.isModalVisible}
                            onOk={() => this.cancel({ id: params.idOrder, reason: params.reason, description: params.description })}
                            onCancel={this.handleClearModalAfterCloseOrCancel}
                            maskStyle={{ opacity: '0.4' }}
                            afterClose={this.handleClearModalAfterCloseOrCancel}
                            cancelText={I18n.t('routes.panel.administration.items.order.modal.cancelText')}
                            okText={I18n.t('routes.panel.administration.items.order.modal.okText')}
                          >
                            <AdvancedSelect
                              label={I18n.t('routes.panel.administration.items.order.modal.reasonOptions')}
                              onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.reason'), val)}
                              value={params.reason ? Number(params.reason) : null}
                              options={ReasonsTypeArray}
                            />
                            <AdvancedInput
                              label={I18n.t('routes.panel.administration.items.order.modal.reasonTitle')}
                              onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.order.description'), val)}
                              value={params.description ? params.description : null}
                              disabled={params.reason === null}
                              required={params.reason === Reasons.OTHER}
                            />
                          </Modal>
                          <Tooltip title={I18n.t('routes.panel.administration.items.order.dataTable.columns.actions.goToDetailsText')}>
                            <Link to={`${I18n.t('routes.panel.administration.items.order.url')}/${id}`}>
                              <ArrowRightOutlined />
                            </Link>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: OrderSelectors.getPaginated(state),
  absoluteValues: OrderSelectors.getAbsoluteValues(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  fuelSelectives: FuelSelectors.getSelectives(state),
  driverSelectives: DriverSelectors.getSelectives(state),
  statesSelectives: OrderSelectors.getSelectives(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAbsoluteValues: (parameters) => dispatch(OrderActions.getAbsoluteValues(parameters)),
  getPaginated: (parameters) => dispatch(OrderActions.getPaginated(parameters)),
  updateStatus: (parameters, callback) => dispatch(OrderActions.updateStatus(parameters, callback)),
  remove: (id, callback) => dispatch(OrderActions.remove(id, callback)),
  cancel: (data, callback) => dispatch(OrderActions.cancel(data, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
  getFuelSelectives: () => dispatch(FuelActions.getSelectives()),
  getDriversSelectives: () => dispatch(DriverActions.getSelectives()),
  getStatesSelectives: () => dispatch(OrderActions.getSelectives()),
  exportCsv: (parameters, callback) => dispatch(OrderActions.exportCsv(parameters, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderList);
