import getInstance from './config';

export default class SettingsApi {
  static async getSelectives() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/settings/selectives');
    return data;
  }

  static async update(settings) {
    const instance = await getInstance();
    const { data } = await instance.put('/v1/settings/', settings);
    return data;
  }
}
