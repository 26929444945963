import { Translate } from 'react-redux-i18n';
import React from 'react';

const AddressType = {
  HOUSE: 1,
  WORK: 2,
  OTHER: 3,
};

export const AddressTypeArray = [
  { id: AddressType.HOUSE, name: <Translate value="enum.addressType.house" /> },
  { id: AddressType.WORK, name: <Translate value="enum.deliveryType.work" /> },
  { id: AddressType.OTHER, name: <Translate value="enum.deliveryType.outro" /> },
];

export const GetAddressTypeTitle = (value) => (AddressTypeArray.find((o) => o.id === value).name);

export default AddressType;
