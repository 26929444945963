import React from 'react';
import { Translate } from 'react-redux-i18n';

const DeliveryType = {
  FAST: 1,
  SCHEDULED: 2,
};

export const DeliveryTypeArray = [
  { id: DeliveryType.FAST, name: <Translate value="enum.deliveryType.fast" /> },
  { id: DeliveryType.SCHEDULED, name: <Translate value="enum.deliveryType.scheduled" /> },
];

export const GetDeliveryTypeTitle = (value) => (DeliveryTypeArray.find((o) => o.id === value).name);

export default DeliveryType;
