import {
  ACTION_SAVE_FUEL_SUPPLY_REPORT,
} from '../actions/fuel-supply';

const initialState = {
  reportData: null,
};

export default function fuelSupply(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_FUEL_SUPPLY_REPORT:
      state = {
        ...state,
        reportData: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getReport(state) {
  return state.fuelSupply.reportData;
}
