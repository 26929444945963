import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Icon, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import { FuelActions, AuthActions } from '../../../../app/redux/actions';
import { FuelSelectors, AuthSelectors, LoadingSelectors } from '../../../../app/redux/reducers';

import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import MoneyFormat from '../../../../components/shared/MoneyFormat';

class FuelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dataTableRef = React.createRef();
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    getPaginated(params);
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.administration.items.fuel.messages.deleteSuccess'));
      this.dataTableRef.current.reset(true);
    });
  }

  render() {
    const { Content } = Layout;
    const {
      paginatedData,
      loading,
      me,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.fuel.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>
        <Content className="panel__layout__content">
          <div className="fuels">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.fuel.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.fuel.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.fuel.addNewButtonText')}
                  href={`${I18n.t('routes.panel.administration.items.fuel.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.fuel.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.price.key'),
                      title: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.price.title'),
                      render: (value) => {
                        if (value) {
                          return (
                            <MoneyFormat
                              value={value}
                              precision={2}
                              isMoney
                            />
                          );
                        }
                        return '--';
                      },
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.isAvailable.key'),
                      title: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.isAvailable.title'),
                      render: (value) => {
                        if (typeof value === 'boolean') {
                          return value ? I18n.t('shared.yes') : I18n.t('shared.no');
                        }

                        return '--';
                      },
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.fuel.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip title={I18n.t('routes.panel.administration.items.fuel.dataTable.columns.actions.removeText')}>
                              <Button
                                type="link"
                                disabled={me && me.id === id}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip title={I18n.t('routes.panel.administration.items.fuel.dataTable.columns.actions.goToDetailsText')}>
                            <Link to={`${I18n.t('routes.panel.administration.items.fuel.url')}/${id}`}>
                              <EditOutlined />
                            </Link>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: FuelSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(FuelActions.getPaginated(parameters)),
  remove: (id, callback) => dispatch(FuelActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FuelList);
