import React from 'react';
import { Table } from 'antd';
import { I18n } from 'react-redux-i18n';
import AdvancedButton from '../AdvancedButton';
import { ArrowRightOutlined } from '@ant-design/icons';

export const defaultValues = {
  limit: 10,
  total: 0,
  page: 1,
  data: null,
};

class SearchDataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultValues;
    if (!this.props.notGetOnStart) {
      this.props.getMethod(defaultValues);
    }
  }

  async componentDidMount() {
    const { getMethod } = this.props;
    const { limit } = this.state;

    const data = await getMethod({
      offset: 0,
      limit: limit,
    });
    this.setState({
      data,
      total: data.count,
    });
  }

  getColumns(params) {

    return params.map((o) => (
      {
        title: o.title,
        dataIndex: o.key,
        key: o.key,
        render: o.render ? o.render : undefined,
      }
    ));
  }

  getRows(data) {
    const { onSelect } = this.props;

    if (data) {
      data = data.map((o) => (
        {
          ...o,
          key: o.id ? o.id.toString() : Math.random().toString(36),
          actions: () => (
            <AdvancedButton
              icon={<ArrowRightOutlined />}
              onClick={() => onSelect(o.id)}
            />
          ),
        }
      ));
    }

    return data;
  }

  isMultiple() {
    const { multiple } = this.props;

    if (multiple) {
      return {
        rowSelection: {
          type: 'checkbox',
          ...this.rowSelection(),
        },
      };
    }
  }

  async handleTableChange(pagination) {
    const { limit } = this.state;
    const { getMethod } = this.props;

    this.setState({
      page: pagination.current,

    }, async () => {
      const data = await getMethod({
        offset: pagination.current > 1 ? (pagination.current - 1) : 0,
        limit: limit,
      });

      this.setState({ data });
    });
  }

  reset() {
    this.setState(defaultValues);
    this.props.getMethod(defaultValues);
  }

  rowSelection() {
    return {
      onChange: (selectedRowKeys, selectedRows) => {
        const { onSelectRow } = this.props;
        onSelectRow(selectedRows);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
      }),
    };
  };

  render() {
    const {
      columns,
      loading,
    } = this.props;

    const {
      total,
      page,
      limit,
      data,
    } = this.state;

    return (
      <div className="search-data-table__wrapper">
        <Table
          dataSource={data && this.getRows(data.rows)}
          columns={this.getColumns(columns)}
          bordered={false}
          loading={loading}
          pagination={{
            current: page,
            total,
            page,
            limit,
          }}
          size="middle"
          onChange={(ev) => this.handleTableChange(ev)}
          locale={{
            emptyText: I18n.t('shared.nothingToShow'),
          }}
          {...this.isMultiple()}
        />
      </div>
    );
  }
}

export default SearchDataTable;
