import { I18n } from 'react-redux-i18n';

function formatCurrency(currency) {
  if (typeof currency !== 'number') {
    currency = currency.replace(I18n.t('shared.moneyPrefix'), '');
    currency = currency.replace(I18n.t('shared.thousandSeparator'), '');
    currency = currency.replace(I18n.t('shared.decimalSeparator'), '.');
    currency = parseFloat(currency);
  }
  return currency;
}

function keepOnlyNumber (value) {
  return value.replace(/\D/g, '');
}

function applyCurrencyMask(value) {
  const appliedMask = Number(value).toLocaleString('pt-br', {
    currency: 'BRL',
    style: 'currency',
  });

  return appliedMask;
}

export {
  formatCurrency,
  keepOnlyNumber,
  applyCurrencyMask
}
