import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import DriverRequests from '../../api/driver';
import { ProfileType } from '../../enum/profileType';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';

export const ACTION_SAVE_DRIVER_PAGINATED = 'ACTION_SAVE_DRIVER_PAGINATED';
export const ACTION_SAVE_DRIVER_AVAILABLES = 'ACTION_SAVE_DRIVER_AVAILABLES';
export const ACTION_SAVE_DRIVER_DETAILS = 'ACTION_SAVE_DRIVER_DETAILS';
export const ACTION_SAVE_DRIVER_SELECTIVES = 'ACTION_SAVE_DRIVER_SELECTIVES';

export const ACTION_CLEAN_DRIVER = 'ACTION_CLEAN_DRIVER';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_DRIVER,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_DRIVER_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_DRIVER_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
      profileType: ProfileType.DRIVER,
    };

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    dispatch({
      type: ACTION_SAVE_DRIVER_PAGINATED,
      payload: null,
    });

    const response = await DriverRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_DRIVER_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_DRIVER_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await DriverRequests.getDetails(id);
      dispatch({
        type: ACTION_SAVE_DRIVER_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getSelectives = (callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_DRIVER_SELECTIVES,
      payload: null,
    });

    response = await DriverRequests.getDriverSelectives();
    dispatch({
      type: ACTION_SAVE_DRIVER_SELECTIVES,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    if (data.email) {
      delete data.email;
    }
    if (data.cellphone) {
      delete data.cellphone;
    }
    await DriverRequests.update(id, data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.administration.items.driver.details.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.DRIVER,
      grantType: 'password',
    };
    await DriverRequests.create(data);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t(`routes.panel.administration.items.driver.details.errors.${err.message}`));
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    await DriverRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
      profileType: ProfileType.DRIVER,
    };

    if (parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    response = await DriverRequests.getPaginated(parameters);

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};

export const getAvailables = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 1000,
      total: parameters ? parameters.total : 0,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'name',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
      profileType: ProfileType.DRIVER,
    };

    if (parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    response = await DriverRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_DRIVER_AVAILABLES,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback();
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};
