import React from 'react';
import { Translate } from 'react-redux-i18n';

const FuelSupplyType = {
  VEHICLE: 1,
  COMPARTMENT: 2,
};

export const FuelSupplyTypeArray = [
  { id: FuelSupplyType.VEHICLE, name: <Translate value="enum.fuelSupplyType.vehicle" /> },
  { id: FuelSupplyType.COMPARTMENT, name: <Translate value="enum.fuelSupplyType.compartment" /> },
];

export const getFuelSupplyTypeTitle = (value) => (FuelSupplyTypeArray.find((o) => o.id === value).name);

export default FuelSupplyType;
