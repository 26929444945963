import getInstance from "./config";

export default class CompanyApi {
  static async create(availableRegionData) {
    const instance = await getInstance();

    const { data } = await instance.post('/v1/available-region', availableRegionData);
    return data;
  }
}
