import { LoadingSearchActions } from '../actions';

export default (
  state = 0,
  action,
) => {
  switch (action.type) {
    case LoadingSearchActions.ACTION_LOADING_SEARCH_START:
      return state + 1;
    case LoadingSearchActions.ACTION_LOADING_SEARCH_END:
      return state - 1;
    default:
      return state;
  }
};

export function getLoadingSearch(state) {
  return state.loadingSearch;
}
