import React from 'react';
import {
  Row,
  Col,
  Layout,
  Card,
  message, Divider,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import AdvancedInput from '../../components/shared/AdvancedInput';
import AdvancedButton from '../../components/shared/AdvancedButton';
import { AuthActions } from '../../app/redux/actions';
import { LoadingSelectors } from '../../app/redux/reducers';
import QueryStringUtils from '../../app/utils/queryString';

class ForgotPasswordCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      recoveryToken: '',
      password: '',
      passwordConfirm: '',
      changingPassword: false,
    };
  }

  componentDidMount() {
    const qs = new QueryStringUtils();
    const email = qs.getByKey('email');

    if (email) {
      this.setState({ email });
    }
  }

  toggleChangingPassword() {
    const { changingPassword } = this.state;

    this.setState({
      changingPassword: !changingPassword,
    });
  }

  validateCode(e) {
    e.preventDefault();
    const { recoveryToken } = this.state;
    if (!recoveryToken) {
      return message.error(I18n.t('routes.forgotPasswordCode.errors.fields'));
    }
    this.toggleChangingPassword();
  }

  changePassword(e) {
    e.preventDefault();
    const { password, passwordConfirm, recoveryToken, email } = this.state;
    const { passwordRecovery } = this.props;

    if (password && passwordConfirm) {
      if (password === passwordConfirm) {
        if (password.length >= 6) {
          passwordRecovery({ password, recoveryToken, email }, () => {
            navigate(I18n.t('routes.login.url'));
            message.success(I18n.t('routes.forgotPasswordCode.messages.passwordChanged'));
          });
        } else {
          return message.error(I18n.t('routes.forgotPasswordCode.errors.passwordNotSecure'));
        }
      } else {
        return message.error(I18n.t('routes.forgotPasswordCode.errors.passwordMismatch'));
      }
    } else {
      return message.error(I18n.t('routes.forgotPasswordCode.errors.fields'));
    }
  }

  render() {
    const { Content } = Layout;
    const {
      email,
      recoveryToken,
      changingPassword,
      password,
      passwordConfirm,
    } = this.state;

    const {
      loading,
    } = this.props;

    return (
      <Content className="forgot-password-code__container">
        <Row
          type="flex"
          justify="center"
        >
          <Col span={6}>
            <Row className="forgot-password-code__logo">
              <Col>
                <img
                  alt="forgot-password-code logo"
                  className="forgot-password-code__logo__img"
                  src="/assets/img/ic_icon_color.png"
                />
              </Col>
            </Row>
            <Card>

              {!changingPassword && (

                <form onSubmit={(e) => this.validateCode(e)}>

                  <Row>
                    <Col>
                      <p className="forgot-password-code__sub-header text-center">
                        {I18n.t('routes.forgotPasswordCode.content.subHeader')}
                      </p>
                      <Divider />
                    </Col>
                  </Row>

                  <Row className="forgot-password-code__input">
                    <Col>
                      <AdvancedInput
                        label={I18n.t('routes.forgotPasswordCode.content.email')}
                        placeholder={I18n.t('routes.forgotPasswordCode.content.emailPlaceholder')}
                        type="email"
                        value={email}
                        disabled
                      />
                    </Col>
                  </Row>

                  <Row className="forgot-password-code__input">
                    <Col>
                      <AdvancedInput
                        label={I18n.t('routes.forgotPasswordCode.content.code')}
                        placeholder={I18n.t('routes.forgotPasswordCode.content.codePlaceholder')}
                        type="tel"
                        value={recoveryToken}
                        onChange={(value) => this.setState({ recoveryToken: value })}
                        disabled={loading && loading > 0}
                      />
                    </Col>
                  </Row>
                  <Row
                    className="forgot-password-code__input forgot-password-code__input__button"
                  >
                    <Col
                      span={12}
                    >
                      <Link to={I18n.t('routes.forgotPassword.url')}>
                        {I18n.t('routes.forgotPasswordCode.content.notReceivedEmail')}
                      </Link>
                    </Col>

                    <Col
                      span={12}
                      className="text-right"
                    >
                      <AdvancedButton
                        text={I18n.t('routes.forgotPasswordCode.content.continueButton')}
                        loading={loading && loading > 0}
                        htmlType="submit"
                      />
                    </Col>
                  </Row>
                </form>
              )}
              {changingPassword && (
                <form onSubmit={(e) => this.changePassword(e)}>

                  <Row>
                    <Col>
                      <p className="forgot-password-code__sub-header text-center">
                        {I18n.t('routes.forgotPasswordCode.content.subHeaderPassword')}
                      </p>
                      <Divider />
                    </Col>
                  </Row>

                  <Row className="forgot-password-code__input">
                    <Col>
                      <AdvancedInput
                        label={I18n.t('routes.forgotPasswordCode.content.password')}
                        placeholder={I18n.t('routes.forgotPasswordCode.content.passwordPlaceholder')}
                        type="password"
                        value={password}
                        onChange={(value) => this.setState({ password: value })}
                        disabled={loading && loading > 0}
                        isPassword
                      />
                    </Col>
                  </Row>

                  <Row className="forgot-password-code__input">
                    <Col>
                      <AdvancedInput
                        label={I18n.t('routes.forgotPasswordCode.content.passwordConfirm')}
                        placeholder={I18n.t('routes.forgotPasswordCode.content.passwordConfirmPlaceHolder')}
                        type="password"
                        value={passwordConfirm}
                        onChange={(value) => this.setState({ passwordConfirm: value })}
                        disabled={loading && loading > 0}
                        isPassword
                      />
                    </Col>
                  </Row>
                  <Row
                    className="forgot-password-code__input forgot-password-code__input__button"
                  >
                    <Col span={12}>
                      <Link
                        className="login__input__button__back"
                        to={I18n.t('routes.forgotPassword.url')}
                      >
                        {I18n.t('routes.forgotPasswordCode.content.cancelButton')}
                      </Link>
                    </Col>
                    <Col
                      span={12}
                      className="text-right"
                    >
                      <AdvancedButton
                        text={I18n.t('routes.forgotPasswordCode.content.continueButton')}
                        loading={loading && loading > 0}
                        htmlType="submit"
                      />
                    </Col>
                  </Row>
                </form>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  passwordRecovery: (data, callback) => dispatch(AuthActions.passwordRecovery(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordCode);
