import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Row,
  Spin,
} from 'antd';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';

import { EmailActions } from '../../../../app/redux/actions';
import { LoadingSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import EmailForm from '../../../../app/forms/email/EmailForm';

class EmailDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.ADD,
    };
  }

  save(id, data) {
    const { create } = this.props;

    try {
      create(data, () => {
        message.success(I18n.t('routes.panel.manage.items.email.details.messages.successCreate'));
        navigate(I18n.t('routes.panel.manage.items.email.url'));
      });
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  render() {
    const { Content } = Layout;
    const { viewType } = this.state;
    const {
      loading,
      id,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.manage.items.email.url')}>
                <Icon type={I18n.t('routes.panel.manage.items.email.icon')} /> <span>{I18n.t('routes.panel.manage.items.email.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.manage.items.email.pageTitleAdd'
                      : 'routes.panel.manage.items.email.pageTitleEdit',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.manage.items.email.icon')} />
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.manage.items.email.pageTitleAdd'
                        : 'routes.panel.manage.items.email.pageTitleEdit',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.manage.items.email.pageDescriptionAdd'
                        : 'routes.panel.manage.items.email.pageDescriptionEdit',
                    )
                  }
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <EmailForm
                      id={id}
                      submitFunction={(dataId, data) => this.save(dataId, data)}
                      viewType={viewType}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  create: (data, callback) => dispatch(EmailActions.create(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailDetails);
