import {
  ACTION_SAVE_DRIVER_PAGINATED,
  ACTION_SAVE_DRIVER_AVAILABLES,
  ACTION_SAVE_DRIVER_DETAILS,
  ACTION_SAVE_DRIVER_SELECTIVES,
} from '../actions/driver';

const initialState = {
  paginatedData: null,
  details: null,
  selectives: null,
  availables: null,
};

export default function driver(state = initialState, action) {
  switch (action.type) {
    case ACTION_SAVE_DRIVER_PAGINATED:
      state = {
        ...state,
        paginatedData: action.payload,
      };
      return state;
    case ACTION_SAVE_DRIVER_AVAILABLES:
      state = {
        ...state,
        availables: action.payload,
      };
      return state;
    case ACTION_SAVE_DRIVER_DETAILS:
      state = {
        ...state,
        details: action.payload,
      };
      return state;
    case ACTION_SAVE_DRIVER_SELECTIVES:
      state = {
        ...state,
        selectives: action.payload,
      };
      return state;
    default:
      return state;
  }
}

export function getPaginated(state) {
  return state.driver.paginatedData;
}

export function getAvailables(state) {
  return state.driver.availables;
}

export function getSelectives(state) {
  return state.driver.selectives;
}

export function getDetails(state) {
  return state.driver.details;
}
