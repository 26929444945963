import { Translate } from 'react-redux-i18n';
import React from 'react';

const OrderStatus = {
  PENDINGPAYMENT: 1,
  PAIED: 2,
  INTRANSIT: 3,
  FUELING: 4,
  PROVIDED: 5,
  CANCELED: 6,
};

export const OrderStatusTypeArray = [
  { id: OrderStatus.PENDINGPAYMENT, name: <Translate value="enum.orderStatusType.pendingPayment" />, color: '#F8D7DA' },
  { id: OrderStatus.PAIED, name: <Translate value="enum.orderStatusType.paied" />, color: '#4091D7' },
  { id: OrderStatus.INTRANSIT, name: <Translate value="enum.orderStatusType.inTransit" />, color: '#FECF6D' },
  { id: OrderStatus.FUELING, name: <Translate value="enum.orderStatusType.fueling" />, color: '#FF9B2B' },
  { id: OrderStatus.PROVIDED, name: <Translate value="enum.orderStatusType.provided" />, color: '#01A56D' },
  { id: OrderStatus.CANCELED, name: <Translate value="enum.orderStatusType.canceled" />, color: '#EB3E41' },
];


export const GetOrderStatus = (value) => (OrderStatusTypeArray.find((o) => o.id === value));
export const GetOrderStatusTitle = (value) => (OrderStatusTypeArray.find((o) => o.id === value).name);

export default OrderStatus;
