export const ACTION_LOADING_SEARCH_START = 'LOADING_SEARCH_START';
export const ACTION_LOADING_SEARCH_END = 'LOADING_SEARCH_END';

export const startLoadingSearch = () => ({
  type: ACTION_LOADING_SEARCH_START,
});

export const stopLoadingSearch = () => ({
  type: ACTION_LOADING_SEARCH_END,
});

export const addLoadingSearch = () => (dispatch) => dispatch(startLoadingSearch());

export const removeLoadingSearch = () => (dispatch) => dispatch(stopLoadingSearch());
