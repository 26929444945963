import { Translate } from 'react-redux-i18n';
import React from 'react';

export const NotificationType = {
  CUSTOMER: 1,
  DRIVER: 2,
  ALL: 3,
};

export const NotificationTypeArray = [
  { id: NotificationType.CUSTOMER, name: <Translate value="enum.notificationType.customer" /> },
  { id: NotificationType.DRIVER, name: <Translate value="enum.notificationType.driver" /> },
  { id: NotificationType.ALL, name: <Translate value="enum.notificationType.all" /> },
];

export const GetNotificationTypeTitle = (value) => (NotificationTypeArray.find((o) => o.id === value).name);
