import React from 'react';
import { I18n } from 'react-redux-i18n';
import CurrencyInput from 'react-currency-input';

class MoneyFormat extends React.PureComponent {
  fixValue(value) {
    if (value) {
      return parseFloat(parseFloat(value).toFixed(this.props.precision));
    }

    return value;
  }

  render() {
    const {
      value,
      precision = 2,
      isMoney
    } = this.props;

    return (
      <CurrencyInput
        isMoney={isMoney}
        className="transparent-input"
        prefix={isMoney && `${I18n.t('shared.moneyPrefix')} `}
        decimalSeparator={`${I18n.t('shared.decimalSeparator')}`}
        thousandSeparator={`${I18n.t('shared.thousandSeparator')}`}
        value={value ? parseFloat(this.fixValue(value)) : undefined}
        disabled
        precision={precision}
      />
    );
  }
}

export default MoneyFormat;
