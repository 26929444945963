import { Translate } from 'react-redux-i18n';
import React from 'react';

const ProblemType = {
  DAMAGE: 1,
  VALUE: 2,
  DELAY: 3,
  SUPPLY: 4,
  OTHERS: 5,
};

export const ProblemTypeArray = [
  { id: ProblemType.DAMAGE, name: <Translate value="enum.problemType.damage" /> },
  { id: ProblemType.VALUE, name: <Translate value="enum.problemType.value" /> },
  { id: ProblemType.DELAY, name: <Translate value="enum.problemType.delay" /> },
  { id: ProblemType.SUPPLY, name: <Translate value="enum.problemType.supply" /> },
  { id: ProblemType.OTHERS, name: <Translate value="enum.problemType.others" /> },
];

export const GetProblemTypeTitle = (value) => (ProblemTypeArray.find((o) => o.id === value).name);
