import { message } from 'antd';
import { I18n } from 'react-redux-i18n';
import {
  addLoading,
  removeLoading,
} from './loading';
import UserRequests from '../../api/user';
import { ProfileType } from '../../enum/profileType';
import { addLoadingSearch, removeLoadingSearch } from './loading-search';
import { removeSpecialChars } from '../../utils/string';

export const ACTION_SAVE_USER_PAGINATED = 'ACTION_SAVE_ADMINISTRATOR_PAGINATED';
export const ACTION_SAVE_USER_DETAILS = 'ACTION_SAVE_ADMINISTRATOR_DETAILS';

export const ACTION_CLEAN_USER = 'ACTION_CLEAN_ADMINISTRATOR';

// cleaners
export const clean = () => ({
  type: ACTION_CLEAN_USER,
  payload: null,
});

export const cleanDetails = () => ({
  type: ACTION_SAVE_USER_DETAILS,
  payload: null,
});

export const cleanPaginated = () => ({
  type: ACTION_SAVE_USER_PAGINATED,
  payload: null,
});

/*
 * BASIC CRUD METHODS
 *
 * getPaginated: get rows for data table
 * getDetails: get details (get by id)
 * update: the PUT method
 * create: the POST method
 */

export const getPaginated = (parameters, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanPaginated());

  try {
    parameters = {
      ...parameters,
      limit: parameters.limit || 10,
      total: parameters.total || 0,
      page: parameters.page || 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
      profileType: parameters && parameters.profileType ? parameters.profileType : ProfileType.USER,
    };

    if (parameters && parameters.profileTypeList && !parameters.fromUser) {
      delete parameters.profileType;
    }

    if (parameters && parameters.searchInput) {
      parameters = {
        ...parameters,
        name: parameters.searchInput,
      };
    }

    dispatch({
      type: ACTION_SAVE_USER_PAGINATED,
      payload: null,
    });

    const response = await UserRequests.getPaginated(parameters);

    dispatch({
      type: ACTION_SAVE_USER_PAGINATED,
      payload: response,
    });

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const getDetails = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  dispatch(cleanDetails());

  try {
    let response = null;
    dispatch({
      type: ACTION_SAVE_USER_DETAILS,
      payload: null,
    });

    if (id !== 'add') {
      response = await UserRequests.getDetails(id);

      dispatch({
        type: ACTION_SAVE_USER_DETAILS,
        payload: response,
      });
    }

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const update = (id, data, callback) => async (dispatch) => {
  dispatch(addLoading());

  try {
    if (data.email) {
      delete data.email;
    }
    if (data.cellphone) {
      delete data.cellphone;
    }

    const response = await UserRequests.update(id, data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

export const create = (data, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    data = {
      ...data,
      profileType: ProfileType.USER,
      grantType: 'password',
    };
    if (data.cellphone) {
      data = {
        ...data,
        cellphone: `${I18n.t('application.phoneCountryCode')}${removeSpecialChars(data.cellphone)}`,
      };
    }

    const response = await UserRequests.create(data);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    if (err && err.response && err.response.isSDK) {
      if (err.response.error === 'email_already_used') {
        message.error(I18n.t('routes.panel.administration.items.user.details.errors.emailAlreadyInUse'));
      }
    } else if(err instanceof Error) {
      message.error(I18n.t(`routes.panel.administration.items.user.details.errors.${err.message}`));
    } else {
      message.error(I18n.t('shared.genericError'));
    }
  } finally {
    dispatch(removeLoading());
  }
};

export const remove = (id, callback) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const response = await UserRequests.remove(id);
    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoading());
  }
};

/*
 * ADVANCED METHODS
 *
 * getPaginatedForSearch: get rows for <AdvancedAutoCompleteInput /> component
 */

export const getPaginatedForSearch = (parameters, callback) => async (dispatch) => {
  let response = null;
  dispatch(addLoadingSearch());

  try {
    parameters = {
      ...parameters,
      limit: parameters ? parameters.limit : 10,
      total: parameters ? parameters.total : 0,
      page: parameters ? parameters.page : 1,
      orderBy: parameters && parameters.orderBy ? parameters.orderBy : 'createdAt',
      isDESC: parameters && typeof parameters.isDESC === 'boolean' ? parameters.isDESC : true,
      profileType: ProfileType.USER,
    };

    response = await UserRequests.getPaginated(parameters);

    if (callback && typeof callback === 'function') {
      callback(response);
    }
  } catch (err) {
    message.error(I18n.t('shared.genericError'));
  } finally {
    dispatch(removeLoadingSearch());
  }

  return response;
};
