import getInstance from './config';

export default class FuelApi {
  static async getPaginated(params) {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/fuel', { params });
    return data;
  }

  static async getDetails(id) {
    const instance = await getInstance();
    const { data } = await instance.get(`/v1/fuel/${id}`);
    return data;
  }

  static async getSelectives() {
    const instance = await getInstance();
    const { data } = await instance.get('/v1/fuel/selectives');
    return data;
  }

  static async update(id, userData) {
    const instance = await getInstance();
    const { data } = await instance.put(`/v1/fuel/${id}`, userData);
    return data;
  }

  static async create(userData) {
    const instance = await getInstance();
    const { data } = await instance.post('/v1/fuel', userData);
    return data;
  }

  static async remove(id) {
    const instance = await getInstance();
    const { data } = await instance.delete(`/v1/fuel/${id}`);
    return data;
  }
}
