import React from 'react';
import {
  Breadcrumb,
  Col,
  Divider,
  Icon,
  Layout,
  message,
  Modal,
  Row,
  Spin,
  Tag,
} from 'antd';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Link, navigate } from '@reach/router';
import { I18n } from 'react-redux-i18n';
import { GoogleOutlined } from '@ant-design/icons';
import { OrderActions } from '../../../../app/redux/actions';
import { LoadingSelectors, OrderSelectors } from '../../../../app/redux/reducers';
import { ViewType } from '../../../../app/enum/viewType';
import OrderForm from '../../../../app/forms/order/OrderForm';
import { GetProblemTypeTitle } from '../../../../app/enum/problemType';
import { humanizeDateTime } from '../../../../app/utils/date';
import { maskDocument } from '../../../../app/utils/string';
import MoneyFormat from '../../../../components/shared/MoneyFormat';
import { GetDeliveryTypeTitle } from '../../../../app/enum/deliveryType';
import OrderStatus, { GetOrderStatus } from '../../../../app/enum/orderStatusType';
import { GetAddressTypeTitle } from '../../../../app/enum/addressType';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import { ProfileType } from '../../../../app/enum/profileType';

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewType: ViewType.EDIT,
      imagePreview: false,
    };
  }

  componentDidMount() {
    const { getDetails, id } = this.props;

    this.setState({
      viewType: id !== 'add' ? ViewType.EDIT : ViewType.ADD,
    });

    getDetails(id);
  }

  toggleImagePreview(url) {
    this.setState({
      imagePreview: url || null,
    });
  }

  save(id, data) {
    const { update, create } = this.props;

    try {
      if (this.state.viewType === ViewType.ADD) {
        create(data, () => {
          message.success(I18n.t('routes.panel.administration.items.order.details.messages.successCreate'));
          navigate(I18n.t('routes.panel.administration.items.order.url'));
        });
      } else {
        update(id, data, () => {
          message.success(I18n.t('routes.panel.administration.items.order.details.messages.successEdit'));
          navigate(I18n.t('routes.panel.administration.items.order.url'));
        });
      }
    } catch (e) {
      message.error(I18n.t('shared.genericError'));
    }
  }

  calculateDeliveredLiters(payment) {
    return parseFloat((payment.totalAmount - payment.tipAmount - payment.deliveryFee
      - (payment.hasRefund ? payment.refundAmount : 0)) / payment.fuelPrice).toFixed(3);
  }

  showCancellationProfile(details) {
    let cancellationProfile;
    if (!details.cancellationProfile) {
      cancellationProfile = '--';
    }
    if (details.cancellationProfile === ProfileType.ADMIN) {
      cancellationProfile = I18n.t('routes.panel.administration.items.order.details.values.admin');
    }
    if (details.cancellationProfile === ProfileType.USER) {
      cancellationProfile = I18n.t('routes.panel.administration.items.order.details.values.customer');
    }

    return cancellationProfile;
  }

  render() {
    const { Content } = Layout;
    const { viewType, imagePreview } = this.state;

    const {
      loading,
      id,
      details,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={I18n.t('routes.panel.administration.items.order.url')}>
                <Icon type={I18n.t('routes.panel.administration.items.order.icon')} /> <span>{I18n.t('routes.panel.administration.items.order.pageTitle')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>
                {
                  I18n.t(
                    viewType === ViewType.ADD
                      ? 'routes.panel.administration.items.order.pageTitleAdd'
                      : 'routes.panel.administration.items.order.pageTitleEdit',
                  )
                }
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__title">
                <h2 className="panel__layout__content__title__value">
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.order.icon')} />
                  </span>
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.administration.items.order.pageTitleAdd'
                        : 'routes.panel.administration.items.order.pageTitleEdit',
                    )
                  }
                </h2>
                <p className="panel__layout__content__title__value__description">
                  {
                    I18n.t(
                      viewType === ViewType.ADD
                        ? 'routes.panel.administration.items.order.pageDescriptionAdd'
                        : 'routes.panel.administration.items.order.pageDescriptionEdit',
                    )
                  }
                </p>
              </div>

              <Divider />

              <div>
                <Row>
                  <Col>
                    <OrderForm
                      id={id}
                      submitFunction={(dataId, data) => this.save(dataId, data)}
                      viewType={viewType}
                      loading={loading}
                    />
                  </Col>
                </Row>
              </div>
            </Spin>
          </div>
        </Content>

        <Content className="panel__layout__content">
          <div>
            <Spin
              spinning={loading > 0}
              tip={I18n.t('shared.loading')}
            >
              <div className="panel__layout__content__details">
                <Modal
                  title={null}
                  visible={imagePreview}
                  onCancel={() => this.toggleImagePreview(null)}
                  footer={[
                    <AdvancedButton
                      type="link"
                      onClick={() => this.toggleImagePreview(null)}
                      key="cancel"
                      text={I18n.t('shared.close')}
                    />,
                  ]}
                >
                  <div className="text-center">
                    {imagePreview && (
                      <img
                        className="img-fluid"
                        alt="previe"
                        src={imagePreview}
                      />
                    )}
                  </div>
                </Modal>

                {details && (
                  <div>
                    <Row>
                      <Col>
                        <h4 className="text-center mt-3 mb-3">
                          {I18n.t('routes.panel.administration.items.order.details.values.title')}
                          <strong>{details.orderNumber}</strong>
                        </h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.client')}
                        </strong>
                        <p>
                          <strong className="d-block">{details.user && details.user.name}</strong>
                          <AdvancedButton
                            text={I18n.t('routes.panel.administration.items.order.details.values.seeClient')}
                            href={`${I18n.t('routes.panel.administration.items.user.url')}/${details.user.id}`}
                            target="_blank"
                            icon={<Icon type={I18n.t('routes.panel.administration.items.user.icon')} />}
                            size="small"
                          />
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.invoiceRequested')}
                        </strong>
                        <p>
                          <strong className="d-block">
                            {details.invoiceRequested
                              ? I18n.t('shared.yes')
                              : I18n.t('shared.no')
                            }
                          </strong>
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.invoiceDocument')}
                        </strong>
                        <p>
                          <strong className="d-block">
                            {details.invoiceDocument
                              && maskDocument(details.invoiceDocument)
                            }
                          </strong>
                        </p>
                      </Col>
                    </Row>

                    <Divider />

                    {details.orderProblems && details.orderProblems.length > 0 && details.orderProblems.map((problem, index) => (
                      <div key={problem.id}>
                        <Row>
                          <Col span={8}>
                            <strong>
                              {`${I18n.t('routes.panel.administration.items.order.details.values.problemType')}${details.orderProblems.length > 1 ? ` - ${index}` : ''}`}
                            </strong>
                            <div>
                              <p className="d-inline">
                                {GetProblemTypeTitle(problem.problemType)}
                              </p>
                              <div className="alert-pointer" />
                            </div>
                          </Col>
                          <Col span={16}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.problemMessage')}
                            </strong>
                            <p>
                              {problem.message}
                            </p>
                          </Col>
                        </Row>

                        <Divider />
                      </div>
                    ))}

                    <Row>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.fuel')}
                        </strong>
                        <p>
                          {details.fuel && details.fuel.name}
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.requestedLiters')}
                        </strong>
                        <p>
                          {details.requestedLiters} {I18n.t('routes.panel.administration.items.order.details.values.liters')}
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.deliveredLiters')}
                        </strong>
                        <p>
                          {details.orderStatus === OrderStatus.PROVIDED ? (
                            <Tag color={details.orderPayment.hasRefund ? '#EB3E41' : '#01A56D'}>
                              { `${this.calculateDeliveredLiters(details.orderPayment)} litros` }
                            </Tag>
                          ) : '--' }
                        </p>
                      </Col>
                    </Row>

                    <Divider />

                    <Row>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.createdAt')}
                        </strong>
                        <p>
                          {humanizeDateTime(details.createdAt, 'DD/MM/YYYY HH:mm:SS')}
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.acceptedAt')}
                        </strong>
                        <p>
                          {humanizeDateTime(details.acceptedAt, 'DD/MM/YYYY HH:mm:SS')}
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.isFullTank')}
                        </strong>
                        <p>
                          {details.isFullTank ? I18n.t('shared.yes') : I18n.t('shared.no')}
                        </p>
                      </Col>
                    </Row>

                    <Divider />

                    <Row>
                      {details.driverSchedule ? (
                        <Col span={8}>
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.driverScheduleDate')}
                          </strong>
                          <p>
                            {`${moment(details.driverSchedule.startAt).format('DD/MM/YYYY')}
                              ${moment(details.driverSchedule.startAt).format('HH:mm')} - ${moment(details.driverSchedule.endAt).format('HH:mm')}`}
                            <strong>
                              {moment(details.driverSchedule.endAt).isBefore(moment())
                                && I18n.t('routes.panel.administration.items.order.details.values.lateOrder')}
                            </strong>
                          </p>
                        </Col>
                      ) : (
                        <Col span={8}>
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.deliveryForecastDate')}
                          </strong>
                          <p>
                            {humanizeDateTime(details.deliveryForecastDate, 'DD/MM/YYYY HH:mm:SS')}
                            <strong>{moment(details.deliveryForecastDate).isBefore(moment()) && I18n.t('routes.panel.administration.items.order.details.values.lateOrder')}</strong>
                          </p>
                        </Col>
                      )}
                      {details.cancellationProfile ? (
                        <Col span={8}>
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.reasonCancellation')}
                          </strong>
                          <p>
                            {details.reasonCancellation}
                          </p>
                        </Col>
                      ) : (
                        <Col span={8}>
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.deliveryFinishedAt')}
                          </strong>
                          <p>
                            {details.deliveryFinishedAt ? humanizeDateTime(details.deliveryFinishedAt, 'DD/MM/YYYY HH:mm:SS') : I18n.t('routes.panel.administration.items.order.details.values.deliveryFinishedAtNull')}
                          </p>
                        </Col>
                      )}
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.orderStatus')}
                        </strong>
                        <span>
                          {details.orderStatus ? (
                            <Tag color={GetOrderStatus(details.orderStatus).color}>
                              {GetOrderStatus(details.orderStatus).name}
                            </Tag>
                          ) : '--' }
                        </span>
                      </Col>
                      <div>
                        <Col
                          span={8}
                          style={{ marginTop: '1.5rem' }}
                        >
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.canceledBy')}
                          </strong>
                          <span>
                            {this.showCancellationProfile(details)}
                          </span>
                        </Col>
                      </div>
                      <div>
                        <Col
                          span={10}
                          style={{ marginTop: '1.5rem' }}
                        >
                          <strong className="d-block">
                            {I18n.t('routes.panel.administration.items.order.details.values.justification')}
                          </strong>
                          <span>
                            {
                              details.descriptionCancellation ?
                              details.descriptionCancellation
                              :
                              '--'
                            }
                          </span>
                        </Col>
                      </div>
                    </Row>

                    <Divider />

                    <Row>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.location')}
                        </strong>
                        <p>
                          {`${details.street}, ${details.number} - ${details.neighborhood} - ${details.city} / ${details.state} - CEP: ${details.zipCode}`}
                          <AdvancedButton
                            text={I18n.t('routes.panel.administration.items.order.details.values.seeOnMap')}
                            href={`https://www.google.com/maps/dir/?api=1&destination=${details.latitude},${details.longitude}`}
                            target="_blank"
                            icon={<GoogleOutlined />}
                            size="small"
                          />
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.deliveryType')}
                        </strong>
                        <p>
                          {details.deliveryType ? GetDeliveryTypeTitle(details.deliveryType) : ''}
                        </p>
                      </Col>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.addressType')}
                        </strong>
                        <p>
                          {details.addressType ? GetAddressTypeTitle(details.addressType) : ''}
                        </p>
                      </Col>
                    </Row>

                    <Divider />

                    <Row>
                      <Col span={8}>
                        <strong className="d-block">
                          {I18n.t('routes.panel.administration.items.order.details.values.complement')}
                        </strong>
                        <p>
                          {details.complement || '--'}
                        </p>
                      </Col>
                    </Row>

                    <Divider />

                    <Row>
                      {details.orderStatus === OrderStatus.CANCELED ? (
                        <>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.previousPayment')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.totalAmount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.refundAmount')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.refundAmount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.payment')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.totalAmount - details.orderPayment.refundAmount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={6}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.payment')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.amount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                          <Col span={6}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.deliveryFee')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.deliveryFee}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                          <Col span={6}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.totalAmount')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.totalAmount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                          <Col span={6}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.tipAmount')}
                            </strong>
                            <p>
                              <MoneyFormat
                                value={details.orderPayment.tipAmount}
                                precision={2}
                                isMoney
                              />
                            </p>
                          </Col>
                        </>
                      )}
                    </Row>

                    {details.vehicle && details.vehicle.model && (
                      <div>
                        <Divider />
                        <Row>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.vehicle')}
                            </strong>
                            <p>
                              {`${details.vehicle.model.brand.name} ${details.vehicle.model.name} (${details.vehicle.plate})`}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    )}

                    {details.orderPayment && (
                      <div>
                        <Divider />

                        <Row>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.clientEvaluation')}
                            </strong>
                            <p>
                              {details.clientEvaluation ? ' * '.repeat(details.clientEvaluation) : 'N/A'}
                            </p>
                          </Col>
                          <Col span={16}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.clientConsideration')}
                            </strong>
                            <p>
                              {details.clientConsideration || '--'}
                            </p>
                          </Col>
                        </Row>

                      </div>
                    )}

                    {details.orderImages && details.orderImages.length > 0 && (
                      <div>
                        <Divider />
                        <Row>
                          <Col span={8}>
                            <strong className="d-block">
                              {I18n.t('routes.panel.administration.items.order.details.values.orderImages')}
                            </strong>
                            {details.orderImages.map((image, index) => (
                              <AdvancedButton
                                size="small"
                                className="mb-3 mr-3"
                                key={index.toString()}
                                text={image.imageName}
                                onClick={() => this.toggleImagePreview(image.imageUrl)}
                              />
                            ))}
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Spin>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  details: OrderSelectors.getDetails(state),
});

const mapDispatchToProps = (dispatch) => ({
  getDetails: (id) => dispatch(OrderActions.getDetails(id)),
  update: (id, data, callback) => dispatch(OrderActions.update(id, data, callback)),
  create: (data, callback) => dispatch(OrderActions.create(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetails);
