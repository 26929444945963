import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Row,
  Col,
  message,
  Divider,
  Collapse,
  Layout,
} from 'antd';
import { I18n } from 'react-redux-i18n';
import { SaveOutlined, SearchOutlined } from '@ant-design/icons';

import AdvancedInput from '../../../components/shared/AdvancedInput';
import {
  AuthSelectors,
  LoadingSelectors,
  UserSelectors,
} from '../../redux/reducers';
import AdvancedButton from '../../../components/shared/AdvancedButton';
import AdvancedCheckbox from '../../../components/shared/AdvancedCheckbox/AdvancedCheckbox';
import AdvancedSelect from '../../../components/shared/AdvancedSelect/AdvancedSelect';
import { EmailType, EmailTypeArray } from '../../enum/emailType';
import AdvancedDatePicker from '../../../components/shared/AdvancedDatePicker';
import DataTable from '../../../components/shared/DataTable';
import { maskCellphone } from '../../utils/string';
import * as DateUtils from '../../utils/date';
import QueryStringUtils from '../../utils/queryString';
import { UserActions } from '../../redux/actions';
import { ProfileType } from '../../enum/profileType';

const { Content } = Layout;
const { Panel } = Collapse;
const qs = new QueryStringUtils();

const defaultFormValues = {
  title: null,
  body: null,
  scheduledDate: null,
  userIdList: null,
  emailType: null,
  isSchedule: false,
  sendForAllUsers: false,
};

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: defaultFormValues,
      params: {
        profileType: null,
        email: null,
        name: null,
      },
    };
    this.dataTableRef = React.createRef();
  }

  componentWillUnmount() {
    const { cleanPaginated } = this.props;
    cleanPaginated();
  }

  onSubmit(e) {
    e.preventDefault();
    let { form } = this.state;

    if (!form.title) {
      return message.error(I18n.t('routes.panel.manage.items.email.details.form.title.errorOne'));
    }

    if (!form.body) {
      return message.error(I18n.t('routes.panel.manage.items.email.details.form.body.errorOne'));
    }

    if (form.isSchedule && !form.scheduledDate) {
      return message.error(I18n.t('routes.panel.manage.items.email.details.form.scheduledDate.errorOne'));
    }

    if (form.isSchedule && form.scheduledDate && form.scheduledDate.isBefore(moment(), 'day')) {
      return message.error(I18n.t('routes.panel.manage.items.email.details.form.scheduledDate.errorTwo'));
    }

    if ((!form.emailType || !form.sendForAllUsers) && (!form.userIdList || (form.userIdList && form.userIdList.length === 0))) {
      return message.error(I18n.t('routes.panel.manage.items.email.details.form.userIdList.errorOne'));
    }

    const { details, submitFunction } = this.props;

    if (form && form.scheduledDate) {
      form = {
        ...form,
        scheduledDate: form.scheduledDate.format(),
      };
    }

    if (form.sendForAllUsers) {
      delete form.userIdList;
    }

    if (details) {
      submitFunction(details.id, form);
    } else {
      submitFunction(null, form);
    }
  }

  onEmailTypeChange(name, value) {
    this.fieldChange(name, value);
    let { params, form } = this.state;
    form = {
      ...form,
      userIdList: null,
    };
    this.setState({ form });

    let profileType = null;
    let profileTypeList = null;

    switch (value) {
      case EmailType.CUSTOMER:
        profileType = ProfileType.USER;
        profileTypeList = null;
        break;
      case EmailType.DRIVER:
        profileType = ProfileType.DRIVER;
        profileTypeList = null;
        break;
      case EmailType.ALL:
        profileType = null;
        profileTypeList = `${ProfileType.DRIVER.toString()},${ProfileType.USER.toString()}`;
        break;
      default:
        profileType = ProfileType.USER;
        profileTypeList = null;
        break;
    }

    params = {
      ...params,
      profileType,
      profileTypeList,
    };

    this.getPaginatedData(params);
  }

  onChangeSendForAllUsers(name, value) {
    const { form } = this.state;
    this.fieldChange(name, value);
    if (!value) {
      this.onEmailTypeChange('emailType', form.emailType);
    }
  }

  onSelectUsers(userIdList) {
    this.fieldChange('userIdList', userIdList.map((o) => o.id));
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    getPaginated(params);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value, type) {
    const { form, params } = this.state;

    if (type === 'filter') {
      params[name] = value ? value.toString() : null;
      this.setState({ params });
    }

    form[name] = value;
    this.setState({ form });
  }


  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const {
      loading,
      paginatedData,
    } = this.props;

    const { form, params } = this.state;

    return (
      <Row>
        <Col>
          <form
            name="form"
            onSubmit={(ev) => this.onSubmit(ev)}
          >
            <Row gutter={16}>
              <Col span={24}>
                <AdvancedInput
                  label={I18n.t('routes.panel.manage.items.email.details.form.title.label')}
                  value={form && form.title}
                  onChange={(val) => this.fieldChange('title', val)}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <AdvancedInput
                  label={I18n.t('routes.panel.manage.items.email.details.form.body.label')}
                  value={form && form.body}
                  onChange={(val) => this.fieldChange('body', val)}
                  isTextArea
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Divider />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <AdvancedCheckbox
                  label={I18n.t('routes.panel.manage.items.email.details.form.isSchedule.label')}
                  onChange={(val) => this.fieldChange('isSchedule', val)}
                  value={form && form.isSchedule}
                />
              </Col>
            </Row>

            {form.isSchedule && (
              <Row gutter={16}>
                <Col span={24}>
                  <AdvancedDatePicker
                    label={I18n.t('routes.panel.manage.items.email.details.form.scheduledDate.label')}
                    onChange={(val) => this.fieldChange('scheduledDate', val)}
                    value={form && form.scheduledDate}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                </Col>
              </Row>
            )}

            <Row gutter={16}>
              <Col span={24}>
                <AdvancedSelect
                  label={I18n.t('routes.panel.manage.items.email.details.form.emailType.label')}
                  value={form && form.emailType}
                  onChange={(val) => this.onEmailTypeChange('emailType', val)}
                  options={EmailTypeArray}
                />
              </Col>
            </Row>

            {form.emailType && (
              <Row gutter={16}>
                <Col span={24}>
                  <AdvancedCheckbox
                    label={I18n.t('routes.panel.manage.items.email.details.form.sendForAllUsers.label')}
                    onChange={(val) => this.onChangeSendForAllUsers('sendForAllUsers', val)}
                    value={form && form.sendForAllUsers}
                  />
                </Col>
              </Row>
            )}

            {form.emailType && !form.sendForAllUsers && (
              <Row gutter={16}>
                <Col span={24}>
                  <Divider />
                  <Content
                    className="panel__layout__content panel__layout__content--advanced-filter"
                    style={{ margin: '0' }}
                  >
                    <Collapse
                      className="advanced-filter"
                    >
                      <Panel
                        header={<strong>{I18n.t('routes.panel.administration.items.order.advancedFilter.panelTitle')}</strong>}
                        key="1"
                      >
                        <Row gutter={16}>
                          <Col span={8}>
                            <AdvancedInput
                              label={I18n.t('routes.panel.administration.items.user.advancedFilter.name.label')}
                              onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.user.advancedFilter.name.key'), val, 'filter')}
                              value={params && params.name}
                            />
                          </Col>

                          <Col span={8}>
                            <AdvancedInput
                              label={I18n.t('routes.panel.administration.items.user.advancedFilter.email.label')}
                              onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.user.advancedFilter.email.key'), val, 'filter')}
                              value={params && params.email}
                            />
                          </Col>

                        </Row>

                        <Row gutter={16}>
                          <Col
                            span={24}
                            className="advanced-filter__search-button text-right"
                          >
                            <AdvancedButton
                              type="link"
                              text={I18n.t('shared.advancedFilter.cleanButtonText')}
                              onClick={() => this.cleanAdvancedFilters()}
                            />
                            <AdvancedButton
                              text={I18n.t('shared.advancedFilter.searchButtonText')}
                              icon={<SearchOutlined />}
                              onClick={() => this.applyAdvancedFilters()}
                            />
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Content>
                  <Divider />
                  <DataTable
                    getMethod={(parameters) => this.getPaginatedData(parameters)}
                    data={paginatedData}
                    loading={loading > 0}
                    ref={this.dataTableRef}
                    onSelectRow={(selectedRows) => this.onSelectUsers(selectedRows)}
                    notGetOnStart
                    selectable
                    columns={
                      [
                        {
                          key: I18n.t('routes.panel.administration.items.user.dataTable.columns.name.key'),
                          title: I18n.t('routes.panel.administration.items.user.dataTable.columns.name.title'),
                          render: (value) => value || '--',
                        },
                        {
                          key: I18n.t('routes.panel.administration.items.user.dataTable.columns.email.key'),
                          title: I18n.t('routes.panel.administration.items.user.dataTable.columns.email.title'),
                          render: (value) => value || '--',
                        },
                        {
                          key: I18n.t('routes.panel.administration.items.user.dataTable.columns.cellphone.key'),
                          title: I18n.t('routes.panel.administration.items.user.dataTable.columns.cellphone.title'),
                          render: (value) => maskCellphone(value) || '--',
                        },
                        {
                          key: I18n.t('routes.panel.administration.items.user.dataTable.columns.createdAt.key'),
                          title: I18n.t('routes.panel.administration.items.user.dataTable.columns.createdAt.title'),
                          render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                        },
                      ]
                    }
                  />
                </Col>
              </Row>
            )}

            <Divider />

            <Row>
              <Col
                span={24}
                className="text-right"
              >
                <AdvancedButton
                  type="link"
                  text={I18n.t('shared.backButtonText')}
                  href={I18n.t('routes.panel.manage.items.email.url')}
                />

                <Divider
                  className="form-button-divider"
                  type="vertical"
                />

                <AdvancedButton
                  htmlType="submit"
                  text={I18n.t('shared.saveButtonText')}
                  icon={<SaveOutlined />}
                />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
  // details: EmailSelectors.getDetails(state),
  paginatedData: UserSelectors.getPaginated(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(UserActions.getPaginated(parameters)),
  cleanPaginated: () => dispatch(UserActions.cleanPaginated()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmailForm);
