import React from 'react';
import { DatePicker } from 'antd';
import { I18n } from 'react-redux-i18n';

class AdvancedDatePicker extends React.PureComponent {
  render() {
    const {
      label,
      placeholder,
      onChange,
      disabled,
      format,
      showTime,
    } = this.props;
    return (
      <div className="advanced-date-picker">
        <label className="advanced-date-picker__label">
          <span className="advanced-date-picker__label__inner">{label}</span>
          <DatePicker
            className="advanced-date-picker__label__input"
            onChange={onChange}
            format={format || I18n.t('shared.dateMask')}
            placeholder={placeholder || I18n.t('shared.selectSomeValue')}
            disabled={disabled || false}
            showToday={false}
            showTime={showTime || undefined}
            showPanels={['time', 'time']}
          />
        </label>
      </div>
    );
  }
}

export default AdvancedDatePicker;
