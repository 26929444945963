import React from 'react';
import {
  Divider,
  Layout,
  Popconfirm,
  Button,
  Row,
  Col,
  message, Breadcrumb, Icon, Collapse, Tooltip,
} from 'antd';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from '@reach/router';

import { DriverActions, AuthActions } from '../../../../app/redux/actions';
import { DriverSelectors, AuthSelectors, LoadingSelectors } from '../../../../app/redux/reducers';

import DataTable from '../../../../components/shared/DataTable';
import AdvancedButton from '../../../../components/shared/AdvancedButton';
import * as DateUtils from '../../../../app/utils/date';
import QueryStringUtils from '../../../../app/utils/queryString';
import AdvancedInput from '../../../../components/shared/AdvancedInput';

const qs = new QueryStringUtils();
const { Panel } = Collapse;

const defaultParams = {
  name: null,
  email: null,
};

class DriverList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: defaultParams,
    };
    this.dataTableRef = React.createRef();
  }

  getPaginatedData(params) {
    const { getPaginated } = this.props;
    getPaginated(params);
  }

  getPaginatedDataAndRefreshTable() {
    const { params } = this.state;
    if (this.dataTableRef) {
      this.dataTableRef.current.reset(false);
      this.getPaginatedData(params);
    }
  }

  fieldChange(name, value) {
    const { params } = this.state;
    params[name] = value ? value.toString() : null;
    this.setState({ params });
  }

  remove(id) {
    this.props.remove(id, () => {
      message.success(I18n.t('routes.panel.administration.items.driver.messages.deleteSuccess'));

      let { params } = this.state;
      params = {
        ...params,
        page: 1,
        offset: 0,
      };
      qs.set({ params });
      this.getPaginatedData(params);
    });
  }

  applyAdvancedFilters() {
    let { params } = this.state;
    params = {
      ...params,
      page: 1,
      offset: 0,
    };

    qs.set({ params });

    this.getPaginatedDataAndRefreshTable(params);
  }

  cleanAdvancedFilters() {
    qs.set({ params: undefined });
    window.location.reload();
  }

  render() {
    const { Content } = Layout;
    const { params } = this.state;
    const {
      paginatedData,
      loading,
      me,
    } = this.props;

    return (
      <div>
        <Content className="panel__layout__content panel__layout__content--breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Icon type="dashboard" /> <span>{I18n.t('routes.panel.pageTitle')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{I18n.t('routes.panel.administration.items.driver.pageTitle')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Content>

        <Content className="panel__layout__content panel__layout__content--advanced-filter">
          <Collapse
            className="advanced-filter"
          >
            <Panel
              header={<strong>{I18n.t('routes.panel.administration.items.user.advancedFilter.panelTitle')}</strong>}
              key="1"
            >
              <Row gutter={16}>
                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.user.advancedFilter.name.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.user.advancedFilter.name.key'), val)}
                    value={params && params.name}
                  />
                </Col>

                <Col span={8}>
                  <AdvancedInput
                    label={I18n.t('routes.panel.administration.items.user.advancedFilter.email.label')}
                    onChange={(val) => this.fieldChange(I18n.t('routes.panel.administration.items.user.advancedFilter.email.key'), val)}
                    value={params && params.email}
                  />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col
                  span={24}
                  className="advanced-filter__search-button text-right"
                >
                  <AdvancedButton
                    type="link"
                    text={I18n.t('shared.advancedFilter.cleanButtonText')}
                    onClick={() => this.cleanAdvancedFilters()}
                  />
                  <AdvancedButton
                    text={I18n.t('shared.advancedFilter.searchButtonText')}
                    icon={<SearchOutlined />}
                    onClick={() => this.applyAdvancedFilters()}
                  />
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </Content>

        <Content className="panel__layout__content">
          <div className="drivers">
            <Row>
              <Col span={12}>
                <h2>
                  <span className="panel__layout__content__title__value__icon">
                    <Icon type={I18n.t('routes.panel.administration.items.driver.icon')} />
                  </span>
                  {I18n.t('routes.panel.administration.items.driver.pageTitle')}
                </h2>
              </Col>
              <Col
                className="text-right"
                span={12}
              >
                <AdvancedButton
                  text={I18n.t('routes.panel.administration.items.driver.addNewButtonText')}
                  href={`${I18n.t('routes.panel.administration.items.driver.url')}/add`}
                  icon={<Icon type={I18n.t('routes.panel.administration.items.driver.icon')} />}
                />
              </Col>
            </Row>

            <Divider />

            <div>
              <DataTable
                getMethod={(parameters) => this.getPaginatedData(parameters)}
                data={paginatedData}
                loading={loading > 0}
                ref={this.dataTableRef}
                columns={
                  [
                    {
                      key: I18n.t('routes.panel.administration.items.driver.dataTable.columns.name.key'),
                      title: I18n.t('routes.panel.administration.items.driver.dataTable.columns.name.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driver.dataTable.columns.email.key'),
                      title: I18n.t('routes.panel.administration.items.driver.dataTable.columns.email.title'),
                      render: (value) => value || '--',
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driver.dataTable.columns.createdAt.key'),
                      title: I18n.t('routes.panel.administration.items.driver.dataTable.columns.createdAt.title'),
                      render: (createdAt) => DateUtils.humanizeDateTime(createdAt, 'DD/MM/YYYY HH:mm'),
                    },
                    {
                      key: I18n.t('routes.panel.administration.items.driver.dataTable.columns.actions.key'),
                      title: '',
                      render: (id) => (
                        <div className="dataTable__item--right">
                          <Popconfirm
                            placement="left"
                            title={I18n.t('shared.confirmTitle')}
                            onConfirm={() => this.remove(id)}
                            okText={I18n.t('shared.yes')}
                            cancelText={I18n.t('shared.no')}
                            disabled={me && me.id === id}
                          >
                            <Tooltip title={I18n.t('routes.panel.administration.items.driver.dataTable.columns.actions.removeText')}>
                              <Button
                                type="link"
                                disabled={me && me.id === id}
                              >
                                <DeleteOutlined />
                              </Button>
                            </Tooltip>
                          </Popconfirm>
                          <Tooltip title={I18n.t('routes.panel.administration.items.driver.dataTable.columns.actions.goToDetailsText')}>
                            <Link to={`${I18n.t('routes.panel.administration.items.driver.url')}/${id}`}>
                              <EditOutlined />
                            </Link>
                          </Tooltip>
                        </div>
                      ),
                    },
                  ]
                }
              />
            </div>
          </div>
        </Content>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  paginatedData: DriverSelectors.getPaginated(state),
  loading: LoadingSelectors.getLoading(state),
  me: AuthSelectors.getMe(state),
});

const mapDispatchToProps = (dispatch) => ({
  getPaginated: (parameters) => dispatch(DriverActions.getPaginated(parameters)),
  remove: (id, callback) => dispatch(DriverActions.remove(id, callback)),
  getMe: () => dispatch(AuthActions.getMe()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DriverList);
