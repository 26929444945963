import { Redirect, Router } from '@reach/router';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import Panel from './Panel';
import Public from './Public';

import Login from '../../containers/login';
import ForgotPassword from '../../containers/forgotPassword';
import ForgotPasswordCode from '../../containers/forgotPasswordCode';

import AdministratorList from '../../containers/panel/administrator/list';
import AdministratorDetails from '../../containers/panel/administrator/details';
import UserList from '../../containers/panel/user/list';
import UserDetails from '../../containers/panel/user/details';
import DriverList from '../../containers/panel/driver/list';
import DriverDetails from '../../containers/panel/driver/details';
import FuelList from '../../containers/panel/fuel/list';
import FuelDetails from '../../containers/panel/fuel/details';
import OrderList from '../../containers/panel/order/list';
import OrderDetails from '../../containers/panel/order/details';
import DriverRatingList from '../../containers/panel/driverRating/list';
import PushNotificationList from '../../containers/panel/pushNotification/list';
import PushNotificationDetails from '../../containers/panel/pushNotification/details';
import EmailList from '../../containers/panel/email/list';
import EmailDetails from '../../containers/panel/email/details';
import Settings from '../../containers/panel/settings';
import VehicleList from '../../containers/panel/vehicle/list';
import VehicleDetails from '../../containers/panel/vehicle/details';
import FuelSupplyList from '../../containers/panel/fuelSupply/list';
import AvailableRegion from '../../containers/panel/AvailableRegion/AvailableRegion';

class Content extends React.PureComponent {
  render() {
    return (
      <Router>
        { /* PUBLIC */}
        <Public
          path={I18n.t('routes.login.url')}
          container={<Login />}
          title={`${I18n.t('routes.login.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forgotPassword.url')}
          container={<ForgotPassword />}
          title={`${I18n.t('routes.forgotPassword.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Public
          path={I18n.t('routes.forgotPasswordCode.url')}
          container={<ForgotPasswordCode />}
          title={`${I18n.t('routes.forgotPasswordCode.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - ADMINISTRATOR */}
        <Panel
          path={I18n.t('routes.panel.administration.items.administrator.url')}
          container={<AdministratorList />}
          title={`${I18n.t('routes.panel.administration.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.administrator.url')}/:id`}
          container={<AdministratorDetails />}
          title={`${I18n.t('routes.panel.administration.items.administrator.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - USERS */}
        <Panel
          path={I18n.t('routes.panel.administration.items.user.url')}
          container={<UserList />}
          title={`${I18n.t('routes.panel.administration.items.user.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.user.url')}/:id`}
          container={<UserDetails />}
          title={`${I18n.t('routes.panel.administration.items.user.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - DRIVER */}
        <Panel
          path={I18n.t('routes.panel.administration.items.driver.url')}
          container={<DriverList />}
          title={`${I18n.t('routes.panel.administration.items.driver.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.driver.url')}/:id`}
          container={<DriverDetails />}
          title={`${I18n.t('routes.panel.administration.items.driver.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - FUEL */}
        <Panel
          path={I18n.t('routes.panel.administration.items.fuel.url')}
          container={<FuelList />}
          title={`${I18n.t('routes.panel.administration.items.fuel.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.fuel.url')}/:id`}
          container={<FuelDetails />}
          title={`${I18n.t('routes.panel.administration.items.fuel.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - ORDER */}
        <Panel
          path={I18n.t('routes.panel.administration.items.order.url')}
          container={<OrderList />}
          title={`${I18n.t('routes.panel.administration.items.order.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.order.url')}/:id`}
          container={<OrderDetails />}
          title={`${I18n.t('routes.panel.administration.items.order.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - DRIVER RATING */}
        <Panel
          path={I18n.t('routes.panel.administration.items.driverRating.url')}
          container={<DriverRatingList />}
          title={`${I18n.t('routes.panel.administration.items.driverRating.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - PUSH NOTIFICATION */}
        <Panel
          path={`${I18n.t('routes.panel.manage.items.pushNotification.url')}`}
          container={<PushNotificationList />}
          title={`${I18n.t('routes.panel.manage.items.pushNotification.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.manage.items.pushNotification.url')}/:id`}
          container={<PushNotificationDetails />}
          title={`${I18n.t('routes.panel.manage.items.pushNotification.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - SEND MAIL */}
        <Panel
          path={`${I18n.t('routes.panel.manage.items.email.url')}`}
          container={<EmailList />}
          title={`${I18n.t('routes.panel.manage.items.email.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.manage.items.email.url')}/:id`}
          container={<EmailDetails />}
          title={`${I18n.t('routes.panel.manage.items.email.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - SETTINGS */}
        <Panel
          path={`${I18n.t('routes.panel.manage.items.settings.url')}`}
          container={<Settings />}
          title={`${I18n.t('routes.panel.manage.items.settings.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - VEHICLES */}
        <Panel
          path={I18n.t('routes.panel.administration.items.vehicle.url')}
          container={<VehicleList />}
          title={`${I18n.t('routes.panel.administration.items.vehicle.pageTitle')} - ${I18n.t('application.name')}`}
        />
        <Panel
          path={`${I18n.t('routes.panel.administration.items.vehicle.url')}/:id`}
          container={<VehicleDetails />}
          title={`${I18n.t('routes.panel.administration.items.vehicle.pageTitle')} - ${I18n.t('application.name')}`}
        />

        { /* PANEL - FUEL SUPPLY */}
        <Panel
          path={I18n.t('routes.panel.administration.items.fuelSupply.url')}
          container={<FuelSupplyList />}
          title={`${I18n.t('routes.panel.administration.items.fuelSupply.pageTitle')} - ${I18n.t('application.name')}`}
        />

        {/* PANEL - Available Regions */}
        <Panel
          path={I18n.t('routes.panel.administration.items.availableRegion.url')}
          container={<AvailableRegion />}
          title={`${I18n.t('routes.panel.administration.items.availableRegion.pageTitle')} - ${I18n.t(
            'application.name',
          )}`}
        />

        { /* ERROR 404 */}
        <Redirect
          from={I18n.t('routes.panel.url')}
          to={I18n.t('routes.login.url')}
        />
      </Router>
    );
  }
}

export default Content;
