import {
  createStore,
  combineReducers,
  applyMiddleware,
  compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import administratorReducer from './reducers/administrator';
import authReducer from './reducers/auth';
import driverReducer from './reducers/driver';
import fuelReducer from './reducers/fuel';
import loadingReducer from './reducers/loading';
import loadingSearchReducer from './reducers/loading-search';
import orderReducer from './reducers/order';
import pushNotificationReducer from './reducers/push-notification';
import userReducer from './reducers/user';
import emailReducer from './reducers/email';
import settingsReducer from './reducers/settings';
import driverScheduleReducer from './reducers/driver-schedule';
import vehicleReducer from './reducers/vehicle';
import brandReducer from './reducers/brand';
import modelReducer from './reducers/model';
import fuelSupplyReducer from './reducers/fuel-supply';
import availableRegionReducer from './reducers/available-region';


export default () => {
  const store = createStore(
    combineReducers({
      i18n: i18nReducer,
      administrator: administratorReducer,
      auth: authReducer,
      driver: driverReducer,
      fuel: fuelReducer,
      loading: loadingReducer,
      loadingSearch: loadingSearchReducer,
      order: orderReducer,
      pushNotification: pushNotificationReducer,
      user: userReducer,
      email: emailReducer,
      settings: settingsReducer,
      driverSchedule: driverScheduleReducer,
      vehicle: vehicleReducer,
      brand: brandReducer,
      model: modelReducer,
      fuelSupply: fuelSupplyReducer,
      availableRegion: availableRegionReducer,
    }),
    compose(
      applyMiddleware(thunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
    ),
  );

  return store;
};
